import { DP_SUPPORT_PHONE_NUMBER } from "redmond";

export const DISRUPTION_PROTECTION_LANDING_TITLE = "We’re here to help.";
export const DISRUPTION_PROTECTION_LANDING_SUBTITLE =
  "If you've experienced a flight disruption within 24 hours of departure, get help now.";

export const DISRUPTION_PROTECTION_LANDING_SUBTITLE_FOR_VI =
    `If you've experienced a flight disruption within 24 hours of departure, get help now. You can also contact support at ${DP_SUPPORT_PHONE_NUMBER.boldLink} for assistance.`;

export const FLIGHT_GOT_DELAYED = "My flight was delayed or canceled";
export const MISSED_CONNECTION = "I missed a connection";

export const REBOOK_TITLE = "Rebook my flight";
export const REBOOK_TITLE_MISSED_CONNECTION = "Rebook my connection";

export const REBOOK_BODY = (cap: string, delayThresholdInHours: string) =>
  `On the day of travel, if your flight is delayed for ${delayThresholdInHours}+ hours or is canceled by the airline, you can book a new flight on any airline available through Capital One Travel. You’ll have the option to select any flight in the same fare class and we'll cover up to ${cap} per traveler.<br/><br/>If you’re not satisfied with your rebooking options, get a full refund of your base fare and taxes without the hassle of contacting the airline or filling out forms.`;
export const REBOOK_BODY_MISSED_CONNECTION = (cap: string) =>
  `On the day of travel, if you miss a connecting flight, you can book a new flight on any airline available through Capital One Travel. You’ll have the option to select any flight in the same fare class and we'll cover up to ${cap} per traveler.<br/><br/>If you’re not satisfied with your rebooking options, get a full refund of your base fare and taxes without the hassle of contacting the airline or filling out forms.`;

export const REBOOK_CTA_COPY = "Rebook my flight";
export const REBOOK_CTA_COPY_MISSED_CONNECTION = "Rebook my connection";

export const REFUND_CTA_COPY = "Get a refund";

export const READ_TERMS_COPY = "Read terms and conditions";
export const FAQ_COPY = "Frequently asked questions";

export const NOTIFICATION_UNDETECTED_FIRST =
  "We haven’t detected a flight disruption for this trip.";

export const NOTIFICATION_UNDETECTED_BANNER = (delayThresholdInHours: string) => `${NOTIFICATION_UNDETECTED_FIRST} Please give us a call at ${DP_SUPPORT_PHONE_NUMBER.boldLink} if your flight has been delayed for ${delayThresholdInHours}+ hours, has been canceled by the airline or you’ve missed a connection due to a flight delay and need help rebooking your flight or getting a refund.`;
export const NOTIFICATION_UNDETECTED_MODAL = `<p>${NOTIFICATION_UNDETECTED_FIRST}</p><p>Please give us a call at ${DP_SUPPORT_PHONE_NUMBER.boldLink} if you’ve experienced a flight disruption and need help rebooking your flight or getting a refund.</p>`;

export const CONTACT_US = "Please contact us for help.";
