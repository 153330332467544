import { PaymentErrorEnum } from "../apis";

export enum PriceFreezePurchaseErrorEnum {}

export enum PaymentPurchaseErrorEnum {
  RedemptionFailure = "RedemptionFailure",
  CardDeclined = "CardDeclined",
}

export enum GeneralPurchaseErrorEnum {
  NoAvailability = "NoAvailability",
  FraudAutoReject = "FraudAutoReject",
  InvalidSession = "InvalidSession",
  InActivity = "Inactivity",
  LikelyFraud = "LikelyFraud",
}

export interface UncategorizedPurchaseError {
  code: PaymentErrorEnum;
  title: string;
  subtitle: string;
}

export type PurchaseError =
  | PriceFreezePurchaseErrorEnum
  | PaymentPurchaseErrorEnum
  | GeneralPurchaseErrorEnum
  | UncategorizedPurchaseError;
