import { actions } from "../actions";
import { put, select } from "redux-saga/effects";
import {
  ILocationQueryLabel,
  LocationQueryEnum,
  IResponse,
  BookedFlightItineraryWithDepartureTime,
  getDepartureSlice,
  AvailabilityRequestEnum,
  AvailabilityResponse,
  getReturnSlice,
  HotelItinerary,
  IIdLodgings,
  PlatformEnum,
  Airport,
  AvailabilityRequest,
  TripCategory,
  IResult,
} from "redmond";
import { fetchLodgingLocationAutocomplete } from "../../../api/v1/cross-sell/fetchLodgingLocationAutocomplete";
import {
  getAirportMap,
  getHotelAvailabilityNextPageToken,
  getHotels,
  getMostUpcomingFlight,
} from "../reducer";
import dayjs from "dayjs";
import { isMobile } from "../utils/userAgent";
import { fetchCrossSellHotelAvailability } from "../../../api/v1/cross-sell/fetchCrossSellHotelAvailability";
import { fetchTravelWalletDetails } from "../../../modules/travel-wallet/actions/actions";

export function* fetchUpcomingFlightCrossSellSaga(
  action: actions.IFetchUpcomingFlightCrossSell
) {
  try {
    let requestBody: AvailabilityRequest;
    const startTime = dayjs();
    switch (action.requestType) {
      case AvailabilityRequestEnum.InitialSearch: {
        const mostUpcomingFlight: BookedFlightItineraryWithDepartureTime =
          yield select(getMostUpcomingFlight);
        const airportMap: {
          [key: string]: Airport;
        } = yield select(getAirportMap);

        if (!airportMap || !mostUpcomingFlight) {
          return;
        }

        const departureSlice = getDepartureSlice(
          mostUpcomingFlight.bookedItinerary as any
        ); // [TODO]: Fix type to used BookedFlightItinerary once it's fixed as a monorepo

        // Gets the last segment to avoid using the layover destinationCode
        const departureLastSegmentIndex = departureSlice
          ? departureSlice.segments.length - 1
          : 0;
        const departureDestinationSegment =
          departureSlice.segments[departureLastSegmentIndex];

        const departureDate = new Date(
          departureDestinationSegment.zonedScheduledArrival ??
            departureDestinationSegment.updatedArrival
        );
        const departureDateWithoutTime =
          dayjs(departureDate).format("YYYY-MM-DD");
        // Since the checkin date does not have time, it's best to compare dates without time in the .find() below (incase departure time is later than the default 12AM GMT)

        const destinationCode =
          departureDestinationSegment?.destination.locationCode ?? "";
        const destination =
          airportMap[destinationCode]?.cityName || destinationCode;

        const returnSlice = getReturnSlice(
          mostUpcomingFlight.bookedItinerary as any
        );

        const returnDate = new Date(
          returnSlice?.segments[0].zonedScheduledArrival ??
            returnSlice?.segments[0].updatedArrival ??
            dayjs(departureDate).add(1, "day").toDate() // if one-way trip, look for hotel for 1 night
        );

        const returnDateWithoutTime = dayjs(returnDate).format("YYYY-MM-DD");
        const adultPaxCount = // if adult pax count is more than 2, default to 2
          mostUpcomingFlight.bookedItinerary.passengers.alone.length +
          mostUpcomingFlight.bookedItinerary.passengers.withLapInfants.length;

        const hotels: {
          [key: string]: HotelItinerary[];
        } = yield select(getHotels);

        const { present: presentHotels = [], future: futureHotels = [] } =
          hotels;

        const bookedHotel = [...presentHotels, ...futureHotels].find(
          (itinerary) => {
            const checkinDate = new Date(itinerary.reservation.checkInDate); // Defaults to 12AM GMT
            const formattedDepartureDateWithoutTime = new Date( // If we use normal departure date, if flight lands 1AM instead of 12AM, hotel would not be in range.
              departureDateWithoutTime
            );
            const formattedReturnDateWithoutTime = returnSlice
              ? new Date(returnDateWithoutTime)
              : new Date(
                  dayjs(departureDateWithoutTime).add(2, "day").toDate()
                ); // if one-way trip, look for hotel booking made for within 2 days of departure
            return (
              checkinDate.valueOf() >=
                formattedDepartureDateWithoutTime.valueOf() &&
              checkinDate.valueOf() <= formattedReturnDateWithoutTime.valueOf()
            );
          }
        );

        if (!bookedHotel) {
          yield put(
            actions.setFlightToHotelXSellTripCategory(
              returnSlice?.segments[0].zonedScheduledArrival
                ? TripCategory.ROUND_TRIP
                : TripCategory.ONE_WAY
            )
          );
          yield put(actions.setSearchedDates(departureDate, returnDate));

          const { correspondingLocation } = yield fetchLocation(
            destination,
            airportMap[destinationCode]?.geography.countryCode as string
          );
          yield put(actions.setSearchedLocationResult(correspondingLocation));
          yield put(
            actions.setSearchedOccupancyCounts({
              adults: adultPaxCount,
              children: [],
            })
          );

          requestBody = {
            lodgingSelection: (correspondingLocation.id as IIdLodgings)
              .lodgingSelection,
            stayDates: {
              from: departureDateWithoutTime,
              until: returnDateWithoutTime,
            },
            guests: {
              adults: adultPaxCount > 2 ? 2 : adultPaxCount,
              children: [],
            },
            platform: isMobile() ? PlatformEnum.Mobile : PlatformEnum.Desktop,
            progressiveConfig: {},
            AvailabilityRequest: AvailabilityRequestEnum.InitialSearch,
          };
        } else {
          return;
        }
        break;
      }
      case AvailabilityRequestEnum.FollowUpSearch: {
        const nextPageToken: string | undefined = yield select(
          getHotelAvailabilityNextPageToken
        );

        if (!nextPageToken) return;

        requestBody = {
          moreToken: nextPageToken,
          progressiveConfig: {},
          AvailabilityRequest: AvailabilityRequestEnum.FollowUpSearch,
        };

        break;
      }
    }
    const availabilityResponse: AvailabilityResponse =
      yield fetchCrossSellHotelAvailability(requestBody);

    yield put(fetchTravelWalletDetails());

    const latency = dayjs().diff(startTime, "seconds", true);
    yield put(
      actions.setCrossSellHotelAvailabilityResults({
        payload: availabilityResponse,
        latency,
      })
    );
  } catch (e) {
    yield put(actions.fetchUpcomingFlightCrossSellFailed());
    console.error(e);
  }
}

export function* fetchLocation(destination: string, countryCode?: string) {
  if (!destination) return { correspondingLocation: undefined };

  let allLocations: IResult[] = [];
  if (countryCode) {
    const locationRequestWithCountryCodeBody: ILocationQueryLabel = {
      LocationQuery: LocationQueryEnum.Label,
      l: `${destination}, ${countryCode}`,
    };
    const { categories: locationWithCountryCodeCategories }: IResponse =
      yield fetchLodgingLocationAutocomplete(
        locationRequestWithCountryCodeBody
      );

    allLocations.push(
      ...locationWithCountryCodeCategories.flatMap(
        (category) => category.results
      )
    );
  }

  const locationRequestBody: ILocationQueryLabel = {
    LocationQuery: LocationQueryEnum.Label,
    l: destination,
  };

  const { categories: locationCategories }: IResponse =
    yield fetchLodgingLocationAutocomplete(locationRequestBody);
  const nonCountryCodeLocations = locationCategories.flatMap(
    (category) => category.results
  );
  allLocations.push(...nonCountryCodeLocations);
  const matchingLocation =
    allLocations.find(
      (result) =>
        result.label.toLowerCase() === destination.toLowerCase() ||
        result.label.toLowerCase().startsWith(`${destination.toLowerCase()},`)
    ) ||
    allLocations.find((result) =>
      result.label.toLowerCase().includes(destination.toLowerCase())
    );

  return {
    correspondingLocation: matchingLocation || nonCountryCodeLocations[0],
  };
}
