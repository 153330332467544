import React from "react";
import { Box, Typography, Dialog, Link } from "@material-ui/core";
import {
  PriceDropProtection as IPriceDropProtection,
  PriceDropProtectionEnum,
  PRICE_DROP_PROTECTION_BODY,
} from "redmond";
import {
  Icon,
  IconName,
  PriceDropProtection,
  MobilePopoverCard,
  ActionLink,
  CloseButtonIcon,
  PriceDropProtectionImage,
  MobilePriceDropProtectionImage,
} from "halifax";
import { PATH_PRICE_DROP_PROTECTION } from "../../../../../utils/paths";
import * as textConstants from "./constants";
import "./styles.scss";
import {
  AVAILABLE,
  getExperimentVariant,
  useExperiments,
  ActiveExperiments,
} from "../../../../../context/experiments";
import { useExperimentsById } from "@capone/experiments";

interface IPriceDropProtectionLinkProps {
  priceDropProtection: IPriceDropProtection;
  isMobile: boolean;
}

export const PriceDropProtectionLink = (
  props: IPriceDropProtectionLinkProps
) => {
  const expState = useExperiments();

  const priceDropCreditGroup = getExperimentVariant(
    expState.experiments,
    ActiveExperiments.PRICE_DROP_CREDIT
  );
  const isPriceDropCreditEnabled = priceDropCreditGroup === AVAILABLE;

  const corpHidePricePrediction =
    useExperimentsById("corp-hide-price-drop-protection")?.variant ===
    "available";

  if (corpHidePricePrediction) {
    return null;
  }

  const { priceDropProtection, isMobile } = props;
  const [priceDropProtectionModalOpen, setPriceDropProtectionModalOpen] =
    React.useState<boolean>(false);

  const renderClosePDPButton = () => {
    return (
      <ActionLink
        className="price-drop-protection-close-button"
        onClick={() => setPriceDropProtectionModalOpen(false)}
        content={<CloseButtonIcon className="close-button-icon" />}
        label="Close"
        showTappableArea={true}
      />
    );
  };

  switch (priceDropProtection.PriceDropProtection) {
    case PriceDropProtectionEnum.IsEligible:
      return (
        <>
          <Link
            className="watch-list-price-drop-protection-link"
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
              setPriceDropProtectionModalOpen(true);
            }}
          >
            <Typography variant="h6" className="price-drop-protection-text">
              {textConstants.PRICE_DROP_LINK_LABEL}
              <Icon name={IconName.InfoCircle} />
            </Typography>
          </Link>
          {isMobile ? (
            <MobilePopoverCard
              topRightButton={renderClosePDPButton()}
              open={priceDropProtectionModalOpen}
              className="mobile-watch-list-price-drop-protection-popup"
              contentClassName="mobile-watch-list-flight-price-drop-protection-content-wrapper"
              onClose={() => setPriceDropProtectionModalOpen(false)}
            >
              <PriceDropProtection
                className="watch-list-price-drop-protection-card"
                image={MobilePriceDropProtectionImage}
                title={textConstants.PDP_TITLE}
                subtitle={textConstants.getSubtitle(
                  priceDropProtection,
                  isPriceDropCreditEnabled
                )}
                header={textConstants.PDP_HEADER}
                ctaText={textConstants.PDP_CTA_TEXT}
                isMobile={true}
                mobileTermsConditionCopy={PRICE_DROP_PROTECTION_BODY}
              />
            </MobilePopoverCard>
          ) : (
            <Dialog
              open={priceDropProtectionModalOpen}
              className="desktop-watch-list-price-drop-protection-popup"
              /** TransitionProps fixes `role` issue bug in MUIv4 - https://github.com/mui/material-ui/issues/18935  */
              TransitionProps={{ role: "none" } as never}
              PaperProps={{
                /* eslint-disable */
                // @ts-ignore: Fix the type definition of PaperProps to include tabIndex.
                tabIndex: 0,
                /* eslint-enable */
              }}
              onClose={() => setPriceDropProtectionModalOpen(false)}
            >
              <Box className="watch-list-price-drop-protection-wrapper">
                <PriceDropProtection
                  className="watch-list-price-drop-protection-card"
                  image={PriceDropProtectionImage}
                  title={textConstants.PDP_TITLE}
                  onClick={() =>
                    window.open(PATH_PRICE_DROP_PROTECTION, "_blank")
                  }
                  onClose={() => setPriceDropProtectionModalOpen(false)}
                  subtitle={textConstants.getSubtitle(
                    priceDropProtection,
                    isPriceDropCreditEnabled
                  )}
                  header={textConstants.PDP_HEADER}
                  ctaText={textConstants.PDP_CTA_TEXT}
                />
              </Box>
            </Dialog>
          )}
        </>
      );
    default:
      return null;
  }
};
