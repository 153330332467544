import {
  Airline,
  AirlineMap,
  BookedFlightItineraryWithDepartureTime,
  CarReservation,
  CfarCancellationOutcome,
  CfarEnum,
  ExerciseEligibilityV1,
  FlightItinerarySegment,
  getDepartureSlice,
  getReturnSlice,
  HotelItinerary,
  HotelPriceFreezeStateEnum,
  PortalType,
  PriceFreezeStatusEnum,
  PriceFreezeWithType,
} from "redmond";
import {
  MultiTicketTypeEnum,
  TravelItinerary,
  BookedFlightItinerary,
  DisruptionProtectionRebookEnum,
  DisruptionProtectionRebook,
  DisruptionProtectionRebookIsRebook,
} from "redmond/trips-module/itinerary";
import { THE_AIRLINE } from "../constants";
import {
  TF,
  TF_PREFIX,
  SABRE,
  AMADEUS,
  AMADEUS_PREFIX,
} from "../components/ItineraryList/components/ItinerariesModal/components/SelfServeCancelFlightModalContent/constants";
import dayjs from "dayjs";
import { CfarOption } from "./cfarExerciseModels";

export const getAirlineName = (
  airlineMap: AirlineMap,
  segments?: FlightItinerarySegment[]
) => {
  if (segments?.length) {
    return (
      airlineMap[segments[0].marketingAirline.code]?.displayName || THE_AIRLINE
    );
  }
  return THE_AIRLINE;
};

export const getAirlineWebLink = (
  flight: BookedFlightItineraryWithDepartureTime,
  airlineMap: { [key: string]: Airline }
) => {
  const depSlice = getDepartureSlice(flight.bookedItinerary);
  const airline = depSlice.segments[0].marketingAirline.code;

  if (!!airlineMap[airline]) {
    return airlineMap[airline]!.webLinks.manageBooking;
  } else {
    return "";
  }
};

export const isTFBooking = (itinerary: TravelItinerary) => {
  let isTFBooking = false;

  if (itinerary.locators?.agent) {
    const { provider, value } = itinerary.locators.agent;

    isTFBooking = value?.startsWith(TF_PREFIX) || provider === TF;
  }

  return isTFBooking;
};

export const isTravaBooking = (itinerary: TravelItinerary) => {
  let isTravaBooking = false;

  if (itinerary.locators?.agent) {
    const { provider, value } = itinerary.locators.agent;

    isTravaBooking =
      value?.startsWith(AMADEUS_PREFIX) ||
      provider === SABRE ||
      provider === AMADEUS;
  }

  return isTravaBooking;
};

export const isMultiTicket = (multiTicketType: MultiTicketTypeEnum) => {
  return (
    multiTicketType === MultiTicketTypeEnum.HackerFare ||
    multiTicketType === MultiTicketTypeEnum.VI
  );
};

export const isViMultiTicket = (multiTicketType: MultiTicketTypeEnum) => {
  return multiTicketType === MultiTicketTypeEnum.VI;
};

export const isDisriptionRebook = (
  disruptionProtectionRebook?: DisruptionProtectionRebook
) => {
  if (!disruptionProtectionRebook) {
    return false;
  }
  return (
    disruptionProtectionRebook.DisruptionProtectionRebook ===
    DisruptionProtectionRebookEnum.IsRebook
  );
};

export const getOriginalItineraryId = (
  disruptionProtectionRebook?: DisruptionProtectionRebook
) => {
  if (isDisriptionRebook(disruptionProtectionRebook)) {
    return (disruptionProtectionRebook as DisruptionProtectionRebookIsRebook)
      .originalItineraryId;
  }
  return null;
};

export const isBeforeDisruptionProtectionDeadlineTime = (
  dpExerciseEligibility: ExerciseEligibilityV1 | undefined
) => {
  if (dpExerciseEligibility === undefined) {
    return false;
  }

  return dayjs().isBefore(dayjs(dpExerciseEligibility.deadlineTime));
};

export const isWithin24Hours = (departureTime?: string) => {
  const timeDiff = dayjs(departureTime).diff(dayjs(), "h");
  return departureTime ? timeDiff <= 24 : false;
};

export const hasUpcomingReturnFlightInBookedItinerary = (
  itinerary: BookedFlightItinerary | undefined,
  isMultiCityItinerary?: boolean
) => {
  if (!itinerary || isMultiCityItinerary) {
    return false;
  }

  const returnSlice = getReturnSlice(itinerary);
  const firstReturnSegment = returnSlice?.segments[0];

  return (
    !!firstReturnSegment &&
    dayjs().isBefore(
      dayjs(
        firstReturnSegment.zonedUpdatedDeparture ??
          firstReturnSegment.zonedScheduledDeparture ??
          firstReturnSegment.updatedDeparture ??
          firstReturnSegment.scheduledDeparture
      )
    )
  );
};

export const cfarEnumToEventOutcomeEnum = (
  cfarEnum: CfarEnum | undefined,
  choice?: CfarOption
) => {
  switch (cfarEnum) {
    case CfarEnum.CfarCash:
      return CfarCancellationOutcome.CfarCash;
    case CfarEnum.CfarCashOrCfarFtc:
      switch (choice) {
        case CfarOption.AIRLINE_CREDIT:
          return CfarCancellationOutcome.CfarFtc;
        case CfarOption.PARTIAL_CASH_REFUND:
          return CfarCancellationOutcome.CfarCash;
        default:
          return CfarCancellationOutcome.CfarCashOrCfarFtc;
      }
    case CfarEnum.CfarCashOrCfarFtcWithPenalty:
      switch (choice) {
        case CfarOption.AIRLINE_CREDIT:
          return CfarCancellationOutcome.CfarFtcWithPenalty;
        case CfarOption.PARTIAL_CASH_REFUND:
          return CfarCancellationOutcome.CfarCash;
        default:
          return CfarCancellationOutcome.CfarCashOrCfarFtcWithPenalty;
      }
    case CfarEnum.TicketedVoid:
      return CfarCancellationOutcome.TicketedVoid;
    case CfarEnum.AirlineFullRefund:
      return CfarCancellationOutcome.AirlineFullRefund;
    case CfarEnum.AirlinePartialRefundOrCfarCash:
      return CfarCancellationOutcome.AirlinePartialRefundOrCfarCash;
    case CfarEnum.ContactCustomerService:
      return CfarCancellationOutcome.ContactCustomerService;
    case CfarEnum.TooCloseToDeparture:
      return CfarCancellationOutcome.TooCloseToDeparture;
    case CfarEnum.CancellationPending:
      return CfarCancellationOutcome.CancellationPending;
    default:
      return CfarCancellationOutcome.Unrecognized;
  }
};

export const isExpired = (priceFreezeWithType: PriceFreezeWithType) => {
  if (priceFreezeWithType.type === "flight") {
    return (
      priceFreezeWithType.priceFreeze.status.Status ===
      PriceFreezeStatusEnum.IsExpired
    );
  } else {
    return (
      priceFreezeWithType.priceFreeze.voucher.state.State ===
      HotelPriceFreezeStateEnum.Expired
    );
  }
};

// This is an expired price freeze where we are also already past the date of travel (return flight / check-out date).
// We want to hide these price freeze cards.
export const isExpiredAndPastTripDate = (
  priceFreezeWithType: PriceFreezeWithType
) => {
  if (!isExpired(priceFreezeWithType)) {
    return false;
  }

  if (priceFreezeWithType.type === "flight") {
    const lastReturnSegment =
      priceFreezeWithType.priceFreeze.frozenFlight.slices[1]?.segmentDetails[
        priceFreezeWithType.priceFreeze.frozenFlight.slices[1]?.segmentDetails
          .length - 1
      ];

    const lastDepartureSegment =
      priceFreezeWithType.priceFreeze.frozenFlight.slices[0]?.segmentDetails[
        priceFreezeWithType.priceFreeze.frozenFlight.slices[0]?.segmentDetails
          .length - 1
      ];

    return dayjs(
      lastReturnSegment?.zonedArrivalTime ??
        lastDepartureSegment?.zonedArrivalTime
    ).isBefore(dayjs());
  } else {
    return dayjs(priceFreezeWithType.priceFreeze.voucher.reservation.end)
      .startOf("d")
      .isBefore(dayjs().startOf("d"));
  }
};

// Corporate Travel

export const getIsCorporateForFlight = (
  flight: BookedFlightItineraryWithDepartureTime | null
) => {
  return !!flight && flight.portalType === PortalType.CORPORATE;
};

export const getIsCorporateForHotel = (hotel: HotelItinerary) => {
  return hotel.portalType === PortalType.CORPORATE;
};

export const getIsCorporateForCar = (car: CarReservation) => {
  return car.portalType === PortalType.CORPORATE;
};
