import { MyTripsFilter } from "redmond";
import { isCorpTenant } from "@capone/common";
import { config } from "../../../api/config";
import { useExperimentsById } from "@capone/experiments";

export const getTabs = (tabs: { label: MyTripsFilter }[]) => {
  const corpHidePriceFreeze =
    useExperimentsById("corp-hide-price-freeze")?.variant === "available";

  const filteredTabs = tabs.filter((tab) => {
    if (corpHidePriceFreeze && tab.label === MyTripsFilter.PRIZE_FREEZES) {
      return false;
    }
    if (
      !isCorpTenant(config.TENANT) &&
      tab.label === MyTripsFilter.TRIP_REQUESTS
    ) {
      return false;
    }
    return true;
  });

  return filteredTabs;
};
