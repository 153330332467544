export const TITLE = "My Trips";
export const SUBTITLE = "View and manage upcoming and past trips";
export const FAQ_TITLE = "Questions about your trip?";
export const FAQ_CTA = "View FAQs";
export const SUPPORT_CTA = "Contact Support";

export const CONTACT_SUPPORT_HEADER = "Contact Support";
export const CONTACT_SUPPORT_TEXT =
  "For any questions regarding your trip, please contact our support team and we'd be happy to help.";
export const CONTACT_SUPPORT_NUMBER = "844-422-6922";

export const SEARCH = "Search";
export const REDESIGN_SUBTITLE = "View and manage upcoming and past trips.";
