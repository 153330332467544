import dayjs from "dayjs";
import { last } from "lodash-es";
import {
  BookedFlightItineraryWithDepartureTime,
  getDepartureSlice,
  getReturnSlice,
  AncillaryKindEnum,
  SelfServeCancelScenario,
  CfarEnum,
  CfarOfferChoice,
  CfarFareRulesSuccessResponse,
} from "redmond";
import { cfarEnumToEventOutcomeEnum } from ".";
import { CfarOption } from "./cfarExerciseModels";

export const getCommonCfarOfferProperties = (
  flight: BookedFlightItineraryWithDepartureTime
) => {
  return {
    lob: "air",
    origin: getDepartureSlice(flight.bookedItinerary).segments[0].origin
      .locationCode,
    status: flight.status,
    destination: last(getDepartureSlice(flight.bookedItinerary).segments)
      ?.destination.locationCode,
    // Advance = departure date - booking date
    advance: flight.bookedItinerary.source?.created
      ? dayjs(
          getDepartureSlice(flight.bookedItinerary)?.segments[0]
            .scheduledDeparture
        ).diff(flight.bookedItinerary.source?.created, "days")
      : undefined,
    carrier: getDepartureSlice(flight.bookedItinerary).segments[0]
      .marketingAirline.code,
    has_skch: !!flight.bookedItinerary.scheduleChange?.id,
    pax_total:
      flight.bookedItinerary.passengers.alone.length +
      2 * flight.bookedItinerary.passengers.withLapInfants.length,
    trip_type: getReturnSlice(flight.bookedItinerary)
      ? "round_trip"
      : "one_way",
    return_date: getReturnSlice(flight.bookedItinerary)?.segments[0]
      .scheduledDeparture,
    departure_date: getDepartureSlice(flight.bookedItinerary).segments[0]
      .scheduledDeparture,
    total_price_usd:
      flight.bookedItinerary.sellingPricing.totalPricing.total.fiat.value,
    price_per_pax_usd:
      flight.bookedItinerary.sellingPricing.pricingByPassenger[0].total.fiat
        .value,
    subtotal_usd:
      flight.bookedItinerary.sellingPricing.totalPricing.subtotal.fiat.value,
    base_total_usd:
      flight.bookedItinerary.sellingPricing.totalPricing.baseWithoutMargin.fiat
        .value,
    charged_cfar_total_usd:
      flight.bookedItinerary.sellingPricing.totalPricing.ancillaries.find(
        (a) => a.kind === AncillaryKindEnum.Cfar
      )?.premium.fiat.value,
    charged_cfar_per_pax_usd:
      flight.bookedItinerary.sellingPricing.pricingByPassenger[0].ancillaries.find(
        (a) => a.kind === AncillaryKindEnum.Cfar
      )?.premium.fiat.value,
    // Compute the number of stops = Sum of [( Number of stops + 1 ) for each segment] - 1
    total_stops:
      getDepartureSlice(flight.bookedItinerary).segments.reduce(
        (stopCount, nextSegment) => stopCount + nextSegment.stops + 1,
        0
      ) -
      1 +
      (getReturnSlice(flight.bookedItinerary)?.segments.reduce(
        (stopCount, nextSegment) => stopCount + nextSegment.stops + 1,
        0
      ) ?? 1) -
      1,
    // days_since_booking = now - booking date
    days_since_booking: flight.bookedItinerary.source?.created
      ? dayjs().diff(flight.bookedItinerary.source?.created, "days")
      : undefined,
    seats_attached: flight.bookedItinerary.seats?.seats
      ? flight.bookedItinerary.seats.seats.length > 0
      : false,
    booking_date: flight.bookedItinerary.source?.created,
    cancel_scenario: SelfServeCancelScenario.CfarCancel,
    agent_locator:
      flight.bookedItinerary.travelItinerary.locators?.agent.unscopedValue,
    days_until_departure: dayjs(
      getDepartureSlice(flight.bookedItinerary)?.segments[0].scheduledDeparture
    ).diff(dayjs(), "day"),
    // elapsed_time_since_booking_in_minutes = now - booking date
    elapsed_time_since_booking_in_minutes: flight.bookedItinerary.source
      ?.created
      ? dayjs().diff(flight.bookedItinerary.source?.created, "minutes")
      : undefined,
  };
};

export const getViewedCfarOfferOptionsProperties = (
  flight: BookedFlightItineraryWithDepartureTime
) => {
  return getCommonCfarOfferProperties(flight);
};

const getCfarOfferChoice: (
  cfarOption: CfarOption | undefined,
  cfarCase: CfarEnum.CfarCashOrCfarFtc | CfarEnum.CfarCashOrCfarFtcWithPenalty
) => CfarOfferChoice = (cfarOption, cfarCase) => {
  switch (cfarOption) {
    case CfarOption.PARTIAL_CASH_REFUND:
      return CfarOfferChoice.CfarCash;
    case CfarOption.AIRLINE_CREDIT:
      return cfarCase === CfarEnum.CfarCashOrCfarFtc
        ? CfarOfferChoice.CfarFtc
        : CfarOfferChoice.CfarFtcWithPenalty;
    default:
      return CfarOfferChoice.Error;
  }
};

export const getTappedCfarOfferOptionsProperties = (
  flight: BookedFlightItineraryWithDepartureTime,
  cfarOption: CfarOption | undefined,
  cfarCase: CfarEnum.CfarCashOrCfarFtc | CfarEnum.CfarCashOrCfarFtcWithPenalty
) => {
  return {
    ...getCommonCfarOfferProperties(flight),
    cfar_offer_choice: getCfarOfferChoice(cfarOption, cfarCase),
  };
};

export const getViewedCfarOfferProperties = (
  flight: BookedFlightItineraryWithDepartureTime,
  cfarOption: CfarOption | undefined,
  cfarCase: CfarEnum.CfarCashOrCfarFtc | CfarEnum.CfarCashOrCfarFtcWithPenalty
) => {
  return {
    ...getCommonCfarOfferProperties(flight),
    cfar_outcome: getCfarOfferChoice(cfarOption, cfarCase),
  };
};

export const getCommonSelfServeConfirmCancellationProperties = (
  flight: BookedFlightItineraryWithDepartureTime,
  cfarOption: CfarOption | undefined,
  cfarFareRule: CfarFareRulesSuccessResponse | undefined,
  isMultiTicket: boolean
) => {
  let cashRefundAmount, ftcRefundAmount;

  // Set the actual cash or ftc refund amount based on Cfar policy and user's option
  switch (cfarFareRule?.Cfar) {
    case CfarEnum.AirlineFullRefund:
    case CfarEnum.CfarCash:
    case CfarEnum.TicketedVoid:
      cashRefundAmount = cfarFareRule.refundPrices.fiat.value;
      break;
    case CfarEnum.CfarCashOrCfarFtc:
    case CfarEnum.CfarCashOrCfarFtcWithPenalty:
      if (cfarOption === CfarOption.AIRLINE_CREDIT) {
        ftcRefundAmount = cfarFareRule.ftcRefundPrices.fiat.value;
      } else {
        cashRefundAmount = cfarFareRule.refundPrices.fiat.value;
      }

      break;
    case CfarEnum.ContactCustomerService:
    case CfarEnum.AirlinePartialRefundOrCfarCash:
    case CfarEnum.TooCloseToDeparture:
    case CfarEnum.CancellationPending:
      break;
  }

  return {
    ...getCommonCfarOfferProperties(flight),
    cfar_outcome: cfarEnumToEventOutcomeEnum(cfarFareRule?.Cfar, cfarOption),
    cfar_cash_amount: cashRefundAmount,
    cfar_ftc_amount: ftcRefundAmount,
    is_multi_ticket: isMultiTicket,
  };
};

export const getSelfServeConfirmCancellationProperties = (
  flight: BookedFlightItineraryWithDepartureTime,
  cfarOption: CfarOption | undefined,
  cfarFareRule: CfarFareRulesSuccessResponse | undefined,
  isMultiTicket: boolean
) => {
  return getCommonSelfServeConfirmCancellationProperties(
    flight,
    cfarOption,
    cfarFareRule,
    isMultiTicket
  );
};

export const getSelfServeCancelResponseSuccessProperties = (
  flight: BookedFlightItineraryWithDepartureTime,
  cfarOption: CfarOption | undefined,
  cfarFareRule: CfarFareRulesSuccessResponse | undefined,
  isMultiTicket: boolean
) => {
  return getCommonSelfServeConfirmCancellationProperties(
    flight,
    cfarOption,
    cfarFareRule,
    isMultiTicket
  );
};

export const getSelfServeCancelResponseFailureProperties = (
  flight: BookedFlightItineraryWithDepartureTime,
  cfarOption: CfarOption | undefined,
  cfarFareRule: CfarFareRulesSuccessResponse | undefined,
  isMultiTicket: boolean
) => {
  return getCommonSelfServeConfirmCancellationProperties(
    flight,
    cfarOption,
    cfarFareRule,
    isMultiTicket
  );
};
