import {
  Airline,
  Airport,
  BookedFlightItineraryWithDepartureTime,
  FlightItineraryResponse,
  FlightItineraryState,
  MyTripsFilter,
} from "redmond";
import { call, put, select } from "redux-saga/effects";
import { fetchFlights } from "../../../api/v1/itinerary/fetchFlights";
import { actions } from "../actions";
import {
  getAirlinesMap,
  getAirportMap,
  getFlights,
  getTripsFilter,
} from "../reducer";
import { setUpMyTripsParams } from "./setUpTripsParams";

export function* fetchFlightsSaga(action: actions.IFetchFlights) {
  try {
    const { tripId } = yield call(setUpMyTripsParams, action);
    const flightItinerariesResponse: FlightItineraryResponse =
      yield fetchFlights(action.request);
    const tripsFilter: MyTripsFilter = yield select(getTripsFilter);
    const flightItineriaries: {
      [key: string]: BookedFlightItineraryWithDepartureTime[];
    } = yield select(getFlights);
    const {
      future = [...flightItineriaries.future],
      canceled = [...flightItineriaries.canceled],
      past = [...flightItineriaries.past],
      present = [...flightItineriaries.present],
    } = flightItinerariesResponse.itineraries;
    const airlines: { [key: string]: Airline } = yield select(
      getAirlinesMap
    );
    const responseAirlinesMapArr = Object.keys(
      flightItinerariesResponse.airlines
    );
    for (let i = 0; i < responseAirlinesMapArr.length; i++) {
      const currentAirline = responseAirlinesMapArr[i];
      if (!airlines[responseAirlinesMapArr[i]]) {
        airlines[currentAirline] =
          flightItinerariesResponse.airlines[currentAirline];
      }
    }

    const airports: { [key: string]: Airport } = yield select(
      getAirportMap
    );
    const responseAirportMapArr = Object.keys(
      flightItinerariesResponse.airports
    );
    for (let i = 0; i < responseAirportMapArr.length; i++) {
      if (!airlines[responseAirportMapArr[i]]) {
        const currentAirport = responseAirportMapArr[i];
        airports[currentAirport] =
          flightItinerariesResponse.airports[currentAirport];
      }
    }
    let selectedFlight = null;
    let isSelectedFlightInPastTrips = false;
    selectedFlight = [...future, ...present].find(
      (flight: BookedFlightItineraryWithDepartureTime) =>
        flight.bookedItinerary.id === tripId
    );
    if (!selectedFlight) {
      const pastSelectedFlight = [...canceled, ...past].find(
        (flight: BookedFlightItineraryWithDepartureTime) =>
          flight.bookedItinerary.id === tripId
      );
      selectedFlight = pastSelectedFlight;
      isSelectedFlightInPastTrips = !!pastSelectedFlight;
    }

    if (isSelectedFlightInPastTrips) {
      if (tripsFilter !== MyTripsFilter.TRAVEL_CREDITS) {
        yield put(actions.setTripsFilter(MyTripsFilter.PAST_TRIPS));
      }
      yield put(actions.populateTripQueryParams(action.history, { tripId }));
    }
    if (selectedFlight) {
      yield put(actions.setSelectedFlight(selectedFlight));
    }

    const requestState = action.request.states;
    switch (true) {
      case requestState.includes(FlightItineraryState.Past) &&
        requestState.includes(FlightItineraryState.Canceled):
        yield put(
          actions.setFlights(
            {
              ...flightItineriaries,
              past,
              canceled,
            },
            [FlightItineraryState.Past, FlightItineraryState.Canceled],
            airports,
            airlines
          )
        );
        break;
      case requestState.includes(FlightItineraryState.Present) &&
        requestState.includes(FlightItineraryState.Future):
        yield put(
          actions.setFlights(
            {
              ...flightItineriaries,
              present,
              future,
            },
            [FlightItineraryState.Present, FlightItineraryState.Future],
            airports,
            airlines
          )
        );
        break;
      default:
        break;
    }
  } catch (e) {
    yield put(actions.fetchFlightsFailed(action.request));
    console.error(e);
  }
}
