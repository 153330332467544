import React, { useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { ActionButton, B2BSpinner, Icon, IconName } from "halifax";
import { RouteComponentProps } from "react-router-dom";
import {
  ScheduleChangeUserActionEnum,
  CallState,
  FlightItineraryState,
} from "redmond";

import { ScheduleChangeState } from "../../component";
import * as textConstants from "../../constants";
import { ScheduleChangeStateModalContentConnectorProps } from "./container";

import "./styles.scss";

export interface IScheduleChangeRequestedModalContentProps
  extends ScheduleChangeStateModalContentConnectorProps,
    RouteComponentProps {
  acceptCallState: CallState;
  action: ScheduleChangeUserActionEnum | null;
  denyCallState: CallState;
  isPassive: boolean;
  onClose: () => void;
  scheduleChangeState: ScheduleChangeState;
  setScheduleChangeState: React.Dispatch<
    React.SetStateAction<ScheduleChangeState>
  >;
  withinContactAirlineLimit: boolean;
}

export const ScheduleChangeStateModal = ({
  acceptCallState,
  action,
  denyCallState,
  fetchFlights,
  history,
  isPassive,
  onClose,
  scheduleChangeState,
  setScheduleChangeState,
  withinContactAirlineLimit,
}: IScheduleChangeRequestedModalContentProps) => {
  useEffect(() => {
    const callState =
      action === ScheduleChangeUserActionEnum.Accept
        ? acceptCallState
        : denyCallState;
    switch (action) {
      case ScheduleChangeUserActionEnum.Accept:
        switch (callState) {
          case CallState.Success:
            setScheduleChangeState(ScheduleChangeState.Accepted);
            break;
          case CallState.Failed:
            setScheduleChangeState(ScheduleChangeState.Failure);
            break;
          default:
            break;
        }
        break;
      case ScheduleChangeUserActionEnum.Deny:
        switch (callState) {
          case CallState.Success:
            setScheduleChangeState(ScheduleChangeState.Requested);
            break;
          case CallState.Failed:
            setScheduleChangeState(ScheduleChangeState.Failure);
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }, [acceptCallState, denyCallState, action]);

  const getIcon = () => {
    switch (scheduleChangeState) {
      case ScheduleChangeState.Failure:
        return <Icon name={IconName.ErrorState} />;
      default:
        return <Icon className="checked-icon" name={IconName.Checked} />;
    }
  };
  const getHeader = () => {
    switch (scheduleChangeState) {
      case ScheduleChangeState.Accepted:
        return isPassive
          ? textConstants.PASSIVE_COPY.ACCEPTED_SUCCESS
          : textConstants.CONF_COPY.ACCEPTED_SUCCESS;
      case ScheduleChangeState.Failure:
        return textConstants.SCHEDULE_CHANGE_FAILURE_TITLE;
      case ScheduleChangeState.Requested:
        return withinContactAirlineLimit
          ? textConstants.CONTACT_AIRLINE_TITLE
          : textConstants.CONF_COPY.DENY_SUCCESS;
      default:
        return textConstants.CONF_COPY.DENY_SUCCESS;
    }
  };
  const getSubtitle = () => {
    switch (scheduleChangeState) {
      case ScheduleChangeState.Accepted:
        return isPassive
          ? textConstants.PASSIVE_COPY.ACCEPTED_SUCCESS_TEXT
          : textConstants.CONF_COPY.ACCEPTED_SUCCESS_TEXT;
      case ScheduleChangeState.Failure:
        if (action === ScheduleChangeUserActionEnum.Accept) {
          return isPassive
            ? textConstants.PASSIVE_COPY.ACCEPTED_FAILURE_TEXT
            : textConstants.CONF_COPY.ACCEPTED_FAILURE_TEXT;
        }
        return textConstants.CONF_COPY.DENY_FAILURE_TEXT;
      case ScheduleChangeState.Requested:
        return withinContactAirlineLimit
          ? textConstants.CONTACT_AIRLINE_SUBTITLE
          : textConstants.CONF_COPY.DENY_SUCCESS_TEXT;
      default:
        return textConstants.CONF_COPY.DENY_SUCCESS_TEXT;
    }
  };

  const onClick =
    scheduleChangeState === ScheduleChangeState.Failure
      ? () => setScheduleChangeState(ScheduleChangeState.Display)
      : () => {
          onClose();
          if (
            scheduleChangeState === ScheduleChangeState.Accepted ||
            scheduleChangeState === ScheduleChangeState.Requested
          ) {
            fetchFlights(
              {
                states: [
                  FlightItineraryState.Canceled,
                  FlightItineraryState.Future,
                  FlightItineraryState.Present,
                  FlightItineraryState.Past,
                ],
                referenceDateTime: dayjs().format(),
              },
              history
            );
          }
        };

  return (
    <Box className="schedule-change-requested-modal">
      {scheduleChangeState === ScheduleChangeState.InProgress ? (
        <B2BSpinner />
      ) : (
        <>
          {getIcon()}
          <Typography className="header" variant="h2" color="textPrimary">
            {getHeader()}
          </Typography>
          <Typography
            className="sub-header"
            color="textSecondary"
            dangerouslySetInnerHTML={{ __html: getSubtitle() }}
            variant="h5"
          />
          <ActionButton
            className="primary-button b2b"
            defaultStyle="h4r-primary"
            message={
              scheduleChangeState === ScheduleChangeState.Failure
                ? textConstants.RETRY
                : textConstants.DONE
            }
            onClick={onClick}
          />
        </>
      )}
    </Box>
  );
};
