import axios from "axios";
import {
  CancellationInfoRequest,
  HomesCancelAgentFullfillResponse,
} from "redmond";

import { config } from "../../config";
import { agentCancelHomeFulfillPath } from "../paths";

/**
 * @description one of two homes cancellation endpoint (this one sends to agent queue to cancel)
 * @param {CancellationInfoRequest} req Contains the customer reservation id
 * @return {HomesCancelAgentFullfillResponse} The response for agent cancel fulfillment
 */
const confirmHomeCancellation = (
  req: CancellationInfoRequest
): Promise<HomesCancelAgentFullfillResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(agentCancelHomeFulfillPath, req, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export default confirmHomeCancellation;
