import React, { useContext } from "react";
import { Box, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { IActionLink } from "halifax";
import { getShowLaunchBanner } from "@capone/common";

import { ClientContext } from "../../../../../../App";
import { config } from "../../../../../../api/config";
import "./styles.scss";

interface IMobileActionLinksProps {
  actions: IActionLink[];
}

export const MobileActionLinks = ({ actions }: IMobileActionLinksProps) => {
  const { sessionInfo } = useContext(ClientContext);

  const showCorpLaunchBanner = getShowLaunchBanner(sessionInfo);

  return (
    <Box
      className={clsx("mobile-action-links-container", config.TENANT, {
        "not-live": showCorpLaunchBanner,
      })}
    >
      {actions.map((action, index) => (
        <Box
          key={`mobile-action-link-${index}`}
          className={clsx("mobile-action-link", {
            "link-separator": index !== actions.length - 1,
          })}
        >
          <Typography
            className={clsx("mobile-action-link-text", action.linkClassName)}
            onClick={action.onClick}
          >
            {action.content}
          </Typography>
          <FontAwesomeIcon
            className="mobile-right-chevron"
            onClick={action.onClick}
            icon={faChevronRight}
          />
        </Box>
      ))}
    </Box>
  );
};
