import { Box, Divider, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { ActionLink, CurrencyFormatters } from "halifax";
import { capitalize } from "lodash";
import React, { useCallback, useContext, useMemo, useState } from "react";
import {
  BookedRooms,
  HotelItinerary,
  IPaymentAmountInfo,
  ItemizedReceiptEventsProperties,
  MyTripsFilter,
  PRINTED_ITEMIZED_RECEIPT,
  PaymentLineItemAncillaryCreditEnum,
  PaymentLineItemEnum,
  PaymentLineItemTravelWallet,
  PaymentLineItemTravelWalletEnum,
  PaymentTypeEnum,
} from "redmond";

import { getHotelStayDuration } from "../../../../../../utils/constants";
import { NIGHT } from "../HotelPaymentModalContent/constants";

import * as textConstants from "./constants";
import "./styles.scss";
import { ClientContext } from "../../../../../../../../App";
import { config } from "../../../../../../../../api/config";
import { trackEvent } from "../../../../../../../../api/v1/analytics/trackEvent";

interface IHotelItemizedReceiptModalContentProps {
  hotel: HotelItinerary;
  documentId?: string;
  isMobile?: boolean;
  tripsFilter: MyTripsFilter;
}

export const HotelItemizedReceiptModalContent = ({
  hotel,
  documentId = textConstants.DEFAULT_DOC_ID,
  isMobile,
  tripsFilter,
}: IHotelItemizedReceiptModalContentProps) => {
  const { reservation, ancillaries } = hotel;
  const paymentAmountInfo: IPaymentAmountInfo = hotel.paymentAmountInfo;
  const paymentBreakdown = hotel.paymentBreakdown;
  const breakdownPayments =
    paymentBreakdown?.payments as PaymentLineItemTravelWallet[];
  const walletItemPayments = breakdownPayments.filter(
    (breakdownPayment) =>
      breakdownPayment.PaymentLineItem === PaymentLineItemEnum.TravelWallet
  );
  const {
    pricing,
    guests,
    lodgingData,
    reservationId,
    createdAt,
    checkInDate,
    checkOutDate,
    bookedRooms,
  } = reservation;
  const { cfar } = ancillaries;
  const { rooms, taxes, tripTotal, feeBreakdown } = pricing;
  const { logo } = useContext(ClientContext);

  const bkedRoomsCount = bookedRooms.reduce(
    (acc: number, bR: BookedRooms) => acc + bR.count,
    0
  );
  const stayLength = dayjs(checkOutDate).diff(checkInDate, "day");
  const basePrice = rooms.fiat.value;
  const fees = feeBreakdown?.total?.value;
  const feesCurrency = feeBreakdown?.total?.currencySymbol;
  const totalTaxes = taxes.fiat.value;
  const cfarCharge = cfar?.premium.amount.fiat.value;
  const priceFreezeDepositCreditAmount = paymentBreakdown.payments.find(
    (p) =>
      "AncillaryCredit" in p &&
      p.AncillaryCredit ===
        PaymentLineItemAncillaryCreditEnum.HotelPriceFreezeDepositCredit
  )?.amount;
  const priceFreezeDepositCredit =
    (priceFreezeDepositCreditAmount &&
      (("fiatValue" in priceFreezeDepositCreditAmount &&
        priceFreezeDepositCreditAmount.fiatValue.amount) ||
        ("amount" in priceFreezeDepositCreditAmount &&
          priceFreezeDepositCreditAmount.amount))) ||
    0;

  const priceFreezeDiscountCreditAmount = paymentBreakdown.payments.find(
    (p) =>
      "AncillaryCredit" in p &&
      p.AncillaryCredit ===
        PaymentLineItemAncillaryCreditEnum.HotelPriceFreezeDiscountCredit
  )?.amount;
  const priceFreezeDiscountCredit =
    (priceFreezeDiscountCreditAmount &&
      (("fiatValue" in priceFreezeDiscountCreditAmount &&
        priceFreezeDiscountCreditAmount.fiatValue.amount) ||
        ("amount" in priceFreezeDiscountCreditAmount &&
          priceFreezeDiscountCreditAmount.amount))) ||
    0;

  const effectiveBasePrice = basePrice - priceFreezeDiscountCredit;

  const effectiveBasePricePerNightRemainderInCents =
    (effectiveBasePrice * 100) % stayLength;

  const effectiveBasePricePerNightInCents =
    (effectiveBasePrice * 100 - effectiveBasePricePerNightRemainderInCents) /
    stayLength;

  const totalTaxesPerNightRemainderInCents = (totalTaxes * 100) % stayLength;

  const totalTaxesPerNightPricePerNightInCents =
    (totalTaxes * 100 - totalTaxesPerNightRemainderInCents) / stayLength;

  const total = tripTotal.fiat.value;
  const effectiveTotal =
    total -
    priceFreezeDepositCredit -
    priceFreezeDiscountCredit -
    walletItemPayments.reduce((prev, curr) => prev + curr.amount.amount, 0);

  const invoiceDate = dayjs(createdAt, "YYYY-MM-DDTHH:mm:ss.SSS").format(
    textConstants.MMDDYYYY_FORMAT
  );
  const guestName = `${guests[0].givenName} ${guests[0].surname}`;
  const resId = `H-${reservationId}`;
  const [printing, setPrinting] = useState(false);

  const addCurrency = (num: number) =>
    `${CurrencyFormatters.get(rooms.fiat.currencyCode).format(Math.abs(num))}`;
  const handlePrint = useCallback(() => {
    setPrinting(true);
    trackEvent({
      eventName: PRINTED_ITEMIZED_RECEIPT,
      properties: {
        trip_id: reservationId,
        travel_type: "hotel",
        active_tab: tripsFilter,
      } satisfies ItemizedReceiptEventsProperties,
    });
    setTimeout(() => {
      const divContents = document.getElementById(documentId)?.innerHTML;
      const cssHeadContents = document.head?.getElementsByTagName("style");
      const cssBodyContents = document.body.getElementsByTagName("style");
      const cssBodyStyle = document.body.getAttribute("style");
      const a = window.open(" ", " ", "height=800, width=400");
      const doc = a?.document;
      if (doc) {
        doc.write("<html><head>");
        for (let i = 0; i < cssHeadContents.length; i++) {
          doc.write(
            `<style>${cssHeadContents.item(i)?.innerHTML || ""}</style>`
          );
        }

        doc.write(`</head><body style="${cssBodyStyle}" >${divContents || ""}`);

        for (let i = 0; i < cssBodyContents.length; i++) {
          doc.write(
            `<style>${cssBodyContents.item(i)?.innerHTML || ""}</style>`
          );
        }
        doc.write("</body></html>");
        if (a)
          a.document.title = `${guestName} - ${resId} ${textConstants.TITLE}`;
        setTimeout(() => {
          a?.print();
          doc.close();
          setPrinting(false);
        }, 500);
      }
    }, 10);
  }, []);

  const paymentInfo = {
    numberDisplay: "",
    rewardsDisplay: "",
    creditApplied: breakdownPayments.some(
      (pmt) =>
        pmt.TravelWallet === PaymentLineItemTravelWalletEnum.TravelWalletCredit
    ),
    offerApplied: breakdownPayments.some(
      (pmt) =>
        pmt.TravelWallet === PaymentLineItemTravelWalletEnum.TravelWalletOffer
    ),
  };

  if (paymentAmountInfo?.PaymentAmountInfo === PaymentTypeEnum.FiatAmountInfo) {
    paymentInfo.numberDisplay = paymentAmountInfo.numberDisplay;
  } else if (
    paymentAmountInfo?.PaymentAmountInfo === PaymentTypeEnum.RewardsAmountInfo
  ) {
    paymentInfo.numberDisplay = paymentAmountInfo.accountDisplayName;
  } else if (
    paymentAmountInfo?.PaymentAmountInfo === PaymentTypeEnum.SplitAmountInfo
  ) {
    paymentInfo.numberDisplay = paymentAmountInfo.fiatInfo.numberDisplay;
    paymentInfo.rewardsDisplay =
      paymentAmountInfo.rewardsInfo.accountDisplayName;
  }

  const renderPrintButton = useMemo(() => {
    if (printing) return null;

    return isMobile ? (
      <></>
    ) : (
      <ActionLink
        className="print-btn"
        content={textConstants.PRINT}
        onClick={handlePrint}
      />
    );
  }, [isMobile, printing]);

  return (
    <Box className="itemized-receipt-modal-container" id={documentId}>
      <Box className="itemized-receipt-modal-content">
        <Box className="itemized-receipt-logo-container">
          {logo}
          {renderPrintButton}
        </Box>
        <Box className="itemized-receipt-title-container">
          <Typography variant="h2">{textConstants.TITLE}</Typography>
        </Box>
        <Box className="itemized-receipt-details">
          <Box className="line-item">
            <Typography variant="body1">{textConstants.HOTEL_NAME}:</Typography>
            <Typography variant="body1" className="hotel-name bold">
              {lodgingData.name}
            </Typography>
          </Box>
          <Box className="line-item">
            <Typography variant="body1">{textConstants.GUEST_NAME}:</Typography>
            <Typography variant="body1" className="bold">
              {guestName}
            </Typography>
          </Box>
          <Box className="line-item gutter-bottom">
            <Typography variant="body1">
              {textConstants.CONFIRMATION_NUMBER}:
            </Typography>
            <Typography variant="body1" className="bold">
              {resId}
            </Typography>
          </Box>
          <Box className="line-item">
            <Typography variant="body1">
              {textConstants.INVOICE_DATE}:
            </Typography>
            <Typography variant="body1" className="bold">
              {invoiceDate}
            </Typography>
          </Box>
          <Box className="line-item">
            <Typography variant="body1">{textConstants.CHECK_IN}:</Typography>
            <Typography variant="body1" className="bold">
              {dayjs(checkInDate).format(textConstants.MMDDYYYY_FORMAT)}
            </Typography>
          </Box>
          <Box className="line-item">
            <Typography variant="body1">{textConstants.CHECKOUT}:</Typography>
            <Typography variant="body1" className="bold">
              {dayjs(checkOutDate).format(textConstants.MMDDYYYY_FORMAT)}
            </Typography>
          </Box>
          <Box className="line-item gutter-bottom">
            <Typography variant="body1">{textConstants.YOUR_STAY}:</Typography>
            <Typography variant="body1" className="bold">
              {`${getHotelStayDuration(reservation)}, ${bkedRoomsCount} ${
                bkedRoomsCount === 1 ? textConstants.ROOM : textConstants.ROOMS
              }`}
            </Typography>
          </Box>
          <Divider className="line-item gutter-bottom" />
          <Typography variant="body1" className="gutter-bottom bold">
            {textConstants.TOTAL_COST_PER_NIGHT}
          </Typography>
          {new Array(stayLength).fill(true).map((_, i) => {
            const dateStr = dayjs(checkInDate)
              .add(i, "day")
              .format(textConstants.MMDDYYYY_FORMAT);

            const priceRoundUpIndex = Math.floor(
              stayLength - effectiveBasePricePerNightRemainderInCents
            ); // index of first 'round-up' bin

            const taxRoundUpIndex = Math.floor(
              stayLength - totalTaxesPerNightRemainderInCents
            );
            return (
              <Box key={dateStr}>
                <Typography variant="body1">{`${dateStr} (${capitalize(
                  NIGHT
                )} ${i + 1})`}</Typography>
                <Box className="line-item">
                  <Typography variant="body1">
                    {textConstants.ACCOMMODATION}
                  </Typography>
                  <Typography variant="body1">
                    {i >= priceRoundUpIndex
                      ? addCurrency(
                          (effectiveBasePricePerNightInCents + 1) / 100
                        )
                      : addCurrency(effectiveBasePricePerNightInCents / 100)}
                  </Typography>
                </Box>
                <Box className="line-item gutter-bottom">
                  <Typography variant="body1">
                    {textConstants.TAXES_AND_FEES}
                  </Typography>
                  <Typography variant="body1">
                    {i >= taxRoundUpIndex
                      ? addCurrency(
                          (totalTaxesPerNightPricePerNightInCents + 1) / 100
                        )
                      : addCurrency(
                          totalTaxesPerNightPricePerNightInCents / 100
                        )}
                  </Typography>
                </Box>
              </Box>
            );
          })}
          <Box className="line-item">
            <Typography variant="body1" className="bold">
              {textConstants.ACCOMMODATION_TOTAL}
            </Typography>
            <Typography variant="body1" className="bold">
              {addCurrency(effectiveBasePrice)}
            </Typography>
          </Box>
          <Box className="line-item">
            <Typography variant="body1" className="bold">
              {textConstants.TAXES_AND_FEES_TOTAL}
            </Typography>
            <Typography variant="body1" className="bold">
              {addCurrency(totalTaxes)}
            </Typography>
          </Box>
          {!!priceFreezeDepositCredit && (
            <Box className="line-item">
              <Typography variant="body1" className="bold">
                {textConstants.PRICE_FREEZE_DEPOSIT_CREDIT}
              </Typography>
              <Typography variant="body1" className="bold">
                {addCurrency(priceFreezeDepositCredit)}
              </Typography>
            </Box>
          )}
          {!!cfarCharge && (
            <Box className="line-item">
              <Typography variant="body1" className="bold">
                {textConstants.CANCEL_FOR_ANY_REASON}
              </Typography>
              <Typography variant="body1" className="bold">
                {addCurrency(cfarCharge)}
              </Typography>
            </Box>
          )}
          {!!fees && (
            <Box className="line-item">
              <Typography variant="body1" className="bold">
                {textConstants.FEES}
              </Typography>
              <Typography variant="body1" className="bold">
                {`${feesCurrency}${fees}`}
              </Typography>
            </Box>
          )}
          {walletItemPayments.map((breakdownPayment) =>
            "breakdown" in breakdownPayment &&
            breakdownPayment.breakdown?.length ? (
              breakdownPayment.breakdown.map((breakdownItem) => (
                <Box className="line-item positive">
                  <Typography variant="body1" className="bold">
                    {breakdownItem.description ||
                      textConstants.TRAVEL_CREDITS_LINE_ITEM_APPLIED}
                  </Typography>
                  <Typography variant="body1" className="bold">
                    -{addCurrency(Math.abs(breakdownItem.amount.amount))}
                  </Typography>
                </Box>
              ))
            ) : (
              <Box className="line-item positive">
                <Typography variant="body1" className="bold">
                  {breakdownPayment.TravelWallet ===
                  PaymentLineItemTravelWalletEnum.TravelWalletCredit
                    ? textConstants.TRAVEL_CREDITS_LINE_ITEM_APPLIED
                    : textConstants.TRAVEL_OFFER_LINE_ITEM_APPLIED}
                </Typography>
                <Typography variant="body1" className="bold">
                  -{addCurrency(Math.abs(breakdownPayment.amount.amount))}
                </Typography>
              </Box>
            )
          )}
          <Box className="line-item gutter-bottom">
            <Typography variant="body1" className="bold">
              {textConstants.TOTAL_COST}
            </Typography>
            <Typography variant="body1" className="bold">
              {addCurrency(effectiveTotal)}
            </Typography>
          </Box>
          <Typography variant="caption" className="line-item gutter-bottom">
            {textConstants.COPY1(config.TENANT)}
          </Typography>
          {((paymentInfo.numberDisplay &&
            !paymentInfo.numberDisplay?.includes("Unavailable")) ||
            paymentInfo.rewardsDisplay ||
            paymentInfo.creditApplied ||
            paymentInfo.offerApplied) && (
            <Box>
              <Typography variant="body1" className="gutter-bottom bold">
                {textConstants.PAYMENT_INFO}
              </Typography>

              {paymentInfo.numberDisplay && (
                <Typography variant="body1" className="gutter-bottom">
                  {`...${paymentInfo.numberDisplay.slice(-4)}`}
                </Typography>
              )}

              {paymentInfo.rewardsDisplay && (
                <Typography variant="body1" className="gutter-bottom">
                  {`...${paymentInfo.rewardsDisplay.slice(-4)}`}
                </Typography>
              )}

              {paymentInfo.creditApplied &&
                !paymentInfo.rewardsDisplay &&
                !paymentInfo.numberDisplay && (
                  <Typography variant="body1" className="gutter-bottom">
                    {textConstants.TRAVEL_CREDIT_APPLIED}
                  </Typography>
                )}

              {paymentInfo.offerApplied &&
                !paymentInfo.rewardsDisplay &&
                !paymentInfo.numberDisplay && (
                  <Typography variant="body1" className="gutter-bottom">
                    {textConstants.TRAVEL_OFFER_APPLIED}
                  </Typography>
                )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
