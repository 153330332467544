import { TravelerWorkflowDialog } from "halifax";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EditTravelerModalState,
  VIEWED_TRAVELER_DETAILS,
  ViewedTravelerDetailsProperties,
  CustomEvents,
  IPerson,
} from "redmond";
import { useExperimentsById } from "@capone/experiments";
import { getPassengers } from "../../../modules/passengers/selectors";
import { trackEvent } from "../../../api/v1/analytics/trackEvent";
import { deleteUserPassenger } from "../../../api/v1/user/deleteUserPassenger";
import { updateUserPassenger } from "../../../api/v1/user/updateUserPassenger";
import { fetchUserPassengers } from "../../../modules/passengers/reducer";
import { config } from "../../../api/config";

export const TravelerModalWrapper = ({
  isMobile,
  userRoles,
}: {
  isMobile: boolean;
  userRoles: string[];
}) => {
  const isHotelLoyaltyEnabled =
    useExperimentsById("corp-hotel-loyalty")?.variant === "available";
  const travelers = useSelector(getPassengers);
  const dispatch = useDispatch();

  const [editTravelersOpen, setEditTravelersOpen] = useState(
    EditTravelerModalState.closed
  );

  const closeEditTravelersModal = useCallback(() => {
    setEditTravelersOpen(EditTravelerModalState.closed);
  }, []);

  const onEditTravelersStateChange = useCallback((ev: unknown) => {
    const newEditModalState =
      (ev as CustomEvent).detail ?? EditTravelerModalState.closed;

    setEditTravelersOpen(newEditModalState);
  }, []);

  useEffect(() => {
    if (editTravelersOpen === EditTravelerModalState.trips) {
      trackEvent({
        eventName: VIEWED_TRAVELER_DETAILS,
        properties: {
          num_traveler_profiles: travelers.length,
        } satisfies ViewedTravelerDetailsProperties,
      });
    }
  }, [editTravelersOpen, travelers.length]);

  useEffect(() => {
    dispatch(fetchUserPassengers());
    document.addEventListener(
      CustomEvents.editTravelersStateChange,
      onEditTravelersStateChange
    );

    return () => {
      document.removeEventListener(
        CustomEvents.editTravelersStateChange,
        onEditTravelersStateChange
      );
    };
  }, []);

  const deleteTraveler = useCallback((personId: string) => {
    deleteUserPassenger(personId).then(() => dispatch(fetchUserPassengers()));
  }, []);

  const isNewTraveler = useCallback(
    (person: IPerson) => {
      return travelers.every((traveler) => traveler.id !== person.id);
    },
    [travelers]
  );

  const upsertTraveler = useCallback((person: IPerson) => {
    updateUserPassenger({
      person,
      isNewTraveler: isNewTraveler(person),
    }).then(() => dispatch(fetchUserPassengers()));
  }, []);

  return (
    <TravelerWorkflowDialog
      disableDriversLicenseValidation
      disallowSelect
      displayModalSubtitle
      showAdditionalInfoSection
      showDriverLicenseSection
      showFrequentFlyerSection
      showHotelLoyaltySection={isHotelLoyaltyEnabled}
      showGenderField
      showNationalityField
      buttonClassName="b2b"
      dialogProps={{
        className: "trips-edit-traveler",
        onClose: closeEditTravelersModal,
        onBackdropClick: closeEditTravelersModal,
        open: editTravelersOpen === EditTravelerModalState.trips,
      }}
      errorMessage="Add or choose one traveler to continue."
      fullScreenWithBanner={isMobile}
      handleDeletePassenger={(personId: string) => {
        deleteTraveler(personId);
      }}
      handleUpdatePassenger={(person: IPerson) => {
        upsertTraveler(person);
      }}
      isMobile={isMobile}
      travelers={travelers}
      tenant={config.TENANT}
      titles={{}}
      dialogContentProps={{}}
      trackEvent={trackEvent}
      userRoles={userRoles}
    />
  );
};
