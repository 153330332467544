import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import {
  ActionButton,
  ButtonWrap,
  FlightInfoCheckbox,
  Icon,
  IconName,
  useDeviceTypes,
} from "halifax";
import { Scheduled } from "redmond/trips-module/flightDisruption";
import clsx from "clsx";

import { MissedConnectionProtectionLandingConnectorProps } from "./container";
import * as constants from "./constants";
import "./styles.scss";
import {
  IVirtualInterliningVariant,
  VirtualIinterliningModal,
} from "../../../common/VirtualIinterliningModal";
import { KnowBeforeRebook } from "../../../common/KnowBeforeRebook/component";
import { ExerciseEligibilityV1, getSlicesFromTravelItinerary } from "redmond";
import {
  formatter,
  getSliceDestination,
} from "../../../TripsList/components/ItineraryList/components/ItinerariesModal/components/SelectFlightInfo/constants";
import { getPaxCount } from "../../../../utils/helpers";
import { pushToRebookFlightList } from "../../../../utils/queryStringHelpers";
import { flightMeetsMcpConditions } from "../../../TripsList/components/ItineraryList/components/FlightCard/helpers";
import { ClientContext } from "../../../../App";

export interface IMissedConnectionProtectionLandingProps
  extends MissedConnectionProtectionLandingConnectorProps,
  RouteComponentProps {
    isAgentPortal?: boolean;
  }

interface IChecked {
  segmentIndex: number;
  segment: Scheduled;
}

export const MissedConnectionProtectionLanding = ({
  flight,
  airports,
  flightDisruptions,
  history,
  sliceIndex,
  isDisruptionProtection24hRuleEnabled,
  setSliceSelectedForMcpVi,
}: IMissedConnectionProtectionLandingProps) => {
  const clientContext = useContext(ClientContext);
  const { isAgentPortal } = clientContext;
  // if we refresh the page we want to set the slice index again
  useEffect(() => {
    if (sliceIndex === null && flight) {
      flightMeetsMcpConditions(
        flight,
        isDisruptionProtection24hRuleEnabled,
        isAgentPortal,
        setSliceSelectedForMcpVi
      );
    }
  }, [sliceIndex, flight]);
  const { matchesMobile } = useDeviceTypes();
  const [variant, setVariant] = useState<IVirtualInterliningVariant | false>(
    false
  );
  const [checked, setChecked] = React.useState<IChecked>();
  const handleChange = ({ segment, segmentIndex }: IChecked) => {
    setChecked({ segment, segmentIndex });
  };

  if (!flight?.bookedItinerary || sliceIndex === null) return null;
  const { hasEmbeddedRoundTrip } = flight.bookedItinerary.travelItinerary;
  const dpExerciseEligibility: ExerciseEligibilityV1 | undefined =
    flightDisruptions?.eligibilityByItinerary[flight.bookedItinerary.id];

  const dpExerciseEligibilitySlice = dpExerciseEligibility.slices[sliceIndex];
  const [firstSegment, ...restSegments] = dpExerciseEligibilitySlice.segments;

  const {
    status: {
      scheduled: { arrivalAirportCode },
    },
  } = dpExerciseEligibilitySlice.segments.slice(-1)[0];
  const {
    status: {
      scheduled: { departureAirportCode: firstSegmentDepartureAirportCode },
    },
  } = firstSegment;

  const travelersCount = getPaxCount(flight);
  const slices = getSlicesFromTravelItinerary(
    flight.bookedItinerary.travelItinerary
  );

  // Virtual Interline Flights will offer only one type of fare the most basic is safe to only check the first index
  const isVITripSelected = flight.bookedItinerary.travelItinerary.slices.some((slice) =>
    slice.segments.some((segment) => segment.isSelfTransferLayover)
  );

  return (
    <Box
      className={clsx("missed-connection-rebook-landing-root", {
        mobile: matchesMobile,
      })}
    >
      <Box className="missed-connection-rebook-landing-container">
        <Box className="header-section">
          <Typography tabIndex={0} className="title-copy" variant="h2">
            {constants.SELECT_THE_CONNECTING_FLIGHT}
            <span>
              <b>{airports[arrivalAirportCode].cityName}</b>
              {` (${arrivalAirportCode})`}
            </span>
          </Typography>
          <Typography className="subtitle-copy" variant="subtitle2">
            <>
              <span>{constants.COST_OF_REBOOKING}</span>
              <ButtonWrap
                className="reminder-section-missed-connection"
                onClick={() => setVariant("missedConnectionGuarantee")}
              >
                <Typography variant="h4">
                  {isVITripSelected
                    ? constants.MISSED_CONNECTION_REBOOKING
                    : constants.MISSED_CONNECTION_GUARANTEE}
                </Typography>
                <Icon name={IconName.InfoCircle} className="info-icon" />
              </ButtonWrap>
            </>
          </Typography>
        </Box>
        <KnowBeforeRebook
          destinationCode={arrivalAirportCode}
          embeddedTrip={hasEmbeddedRoundTrip}
        />
        <Box className="separator" />
        <Box className="itinerary-details">
          <Typography className="flight-destination" variant="h4">
            <span
              dangerouslySetInnerHTML={{
                __html: constants.getItineraryDetailsHeader({
                  airports,
                  date: firstSegment.status.scheduled.departureTimeLocal,
                  destinationCode: arrivalAirportCode,
                  originCode:
                    firstSegment.status.scheduled.departureAirportCode,
                }),
              }}
            />
          </Typography>
          <Typography variant="caption">
            <>
              <span>
                {`${travelersCount} ${travelersCount > 1 ? constants.TRAVELER : constants.TRAVELER
                  } `}
              </span>
              |
              <span>{` ${slices[sliceIndex].segments[0].cabinClassName}`}</span>
            </>
          </Typography>
        </Box>
        <Box className="separator" />
        <Box className="segment-selector">
          <Box className="first-segment-section">
            <Box className="icon-section">
              <Icon
                name={IconName.B2BAirplaneIcon}
                className="airplaine-icon"
              />
              <div className="dashed"></div>
            </Box>
            <Box className="info-section">
              <Typography tabIndex={0} className="origin-city" variant="h4">
                <span>
                  <b>{airports[firstSegmentDepartureAirportCode].cityName}</b>
                </span>
              </Typography>
              <Typography tabIndex={0} className="origin-code" variant="h4">
                {`(${firstSegmentDepartureAirportCode})`}
              </Typography>
            </Box>
          </Box>
          {restSegments.map((s, i) => {
            return (
              <div key={s.status.scheduled.arrivalTimeLocal}>
                <FlightInfoCheckbox
                  variant="radio"
                  checked={
                    checked?.segment.arrivalTimeLocal ===
                    s.status.scheduled.arrivalTimeLocal
                  }
                  onChange={() =>
                    handleChange({
                      // we do plus one, because we removed the first segment
                      segmentIndex: i + 1,
                      segment: s.status.scheduled,
                    })
                  }
                  origin={getSliceDestination(
                    s.status.scheduled.departureAirportCode,
                    airports
                  )}
                  checkboxId={s.status.scheduled.arrivalTimeLocal}
                  destination={getSliceDestination(
                    s.status.scheduled.arrivalAirportCode,
                    airports
                  )}
                  departureDateTime={formatter(
                    s.status.scheduled.departureTimeLocal
                  )}
                  arrivalDateTime={formatter(
                    s.status.scheduled.arrivalTimeLocal ?? ""
                  )}
                  airlineCode={s.status.scheduled.airlineCode}
                  flightNumber={`${s.status.scheduled.airlineCode} ${s.status.scheduled.flightNumber}`}
                  className="slice"
                  key={s.status.scheduled.arrivalTimeLocal}
                />
              </div>
            );
          })}
          <ActionButton
            className={clsx("cta-button", "rebook-connecting-flight", {
              mobile: matchesMobile,
            })}
            defaultStyle="h4r-primary"
            disabled={!Boolean(checked)}
            onClick={() => {
              if (checked && flight) {
                const { segment, segmentIndex } = checked;
                pushToRebookFlightList({
                  history,
                  itineraryId: flight.bookedItinerary.id,
                  origin: segment.departureAirportCode,
                  destination: arrivalAirportCode,
                  activeDisruption: "missed_connection_vi",
                  productRedeemChoice: "missed_connection_vi",
                  hasEmbeddedRoundTrip: `${hasEmbeddedRoundTrip}`,
                  segmentIndex,
                  sliceIndex,
                });
              }
            }}
            ariaLabelText={constants.REBOOK_CONNECTING_FLIGHT}
            message={constants.REBOOK_CONNECTING_FLIGHT}
          />
        </Box>
        <Box className="separator" />
        <Box className="important-info">
          <Typography className="subtitle-copy" variant="h4">
            {constants.IMPORTANT_INFORMATION}
          </Typography>
          <ol>
            {constants.IMPORTANT_INFO_LIST.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ol>
        </Box>
      </Box>
      {variant && (
        <VirtualIinterliningModal
          variant={variant}
          isMobile={matchesMobile}
          isOpen
          onClose={() => setVariant(false)}
        />
      )}
    </Box>
  );
};
