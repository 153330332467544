import {
  BookedFlightItineraryWithDepartureTime,
  getDurationFromSegments,
  getDepartureSlice,
  getReturnSlice,
  Airport,
  getMulticitySlice,
} from "redmond";
import dayjs from "dayjs";

export const removeTimezone = (time: string) =>
  time.replace(/[zZ]|[-+][0-9:]+$/, "");

export const DEPARTURE = "Departure";
export const RETURN = "Return";
export const OUTBOUND = "Outbound";
export const DATE_FORMAT = "ddd, MMM DD";
export const getItinerarySummaryHeader = (
  flight: BookedFlightItineraryWithDepartureTime,
  airportMap: { [key: string]: Airport },
  isOutgoing: boolean
) => {
  const slice = isOutgoing
    ? getDepartureSlice(flight.bookedItinerary)
    : getReturnSlice(flight.bookedItinerary);
  const time = isOutgoing
    ? dayjs(slice?.segments[0].scheduledDeparture)
    : dayjs(slice?.segments[0].scheduledArrival);
  const locationCode =
    slice!.segments[slice!.segments.length - 1].destination.locationCode;
  const cityName = !!airportMap[locationCode]
    ? airportMap[locationCode]!.cityName
    : locationCode;
  return ` to ${cityName} on ${time.format(DATE_FORMAT)}`;
};

export const getMulticityItinerarySummaryHeader = (
  flight: BookedFlightItineraryWithDepartureTime,
  airportMap: { [key: string]: Airport },
  sliceIndex: number
) => {
  const slice = getMulticitySlice(flight.bookedItinerary, sliceIndex);
  const time = dayjs(slice.segments[0].scheduledDeparture);
  const originLocationCode = slice!.segments[0].origin.locationCode;
  const originCityName = airportMap[originLocationCode]!.cityName;
  const destinationLocationCode =
    slice!.segments[slice!.segments.length - 1].destination.locationCode;
  const destinationCityName = airportMap[destinationLocationCode]!.cityName;
  return `${originCityName} (${originLocationCode}) to ${destinationCityName} (${destinationLocationCode}) on ${time.format(
    DATE_FORMAT
  )}`;
};

export const getTime = (
  flight: BookedFlightItineraryWithDepartureTime,
  isOutgoing: boolean,
  multicitySliceIndex?: number
) => {
  const isMulticity = multicitySliceIndex !== undefined;
  const slice = isMulticity
    ? getMulticitySlice(flight.bookedItinerary, multicitySliceIndex)
    : isOutgoing
    ? getDepartureSlice(flight.bookedItinerary)
    : getReturnSlice(flight.bookedItinerary);
  const dep =
    slice!.segments[0].zonedUpdatedDeparture ||
    slice!.segments[0].updatedDeparture ||
    slice!.segments[0].zonedScheduledDeparture ||
    slice!.segments[0].scheduledDeparture;
  const ret =
    slice!.segments[slice!.segments.length - 1].zonedUpdatedArrival ||
    slice!.segments[slice!.segments.length - 1].updatedArrival ||
    slice!.segments[slice!.segments.length - 1].zonedScheduledArrival ||
    slice!.segments[slice!.segments.length - 1].scheduledArrival;
  const departure = dayjs(removeTimezone(dep));
  const returnTime = dayjs(removeTimezone(ret));
  return `${departure.format("h:mm A")} - ${returnTime.format("h:mm A")}`;
};

export const getDuration = (
  flight: BookedFlightItineraryWithDepartureTime,
  isOutgoing: boolean,
  multicitySliceIndex?: number
) => {
  const isMulticity = multicitySliceIndex !== undefined;
  const slice = isMulticity
    ? getMulticitySlice(flight.bookedItinerary, multicitySliceIndex)
    : isOutgoing
    ? getDepartureSlice(flight.bookedItinerary)
    : getReturnSlice(flight.bookedItinerary);
  const duration = getDurationFromSegments(
    slice!.segments,
    (departure, arrival) => dayjs(arrival).diff(dayjs(departure), "m")
  );
  const hours = Math.floor(duration / 60);
  const mins = duration - hours * 60;
  return `${hours}h ${mins}m`;
};
