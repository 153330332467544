import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CancellationInfoRequest,
  HomeCancelInfoResponse,
  HomesItinerary,
} from "redmond";

import getHomeCancellationInfo from "../../../../../../../../api/v1/itinerary/getHomeCancellationPolicy";
import { Box, Divider, Typography } from "@material-ui/core";
import {
  ActionButton,
  B2BSpinnerWithText,
  Icon,
  ImportantInfoList,
  IconName,
} from "halifax";
import * as constants from "./constants";
import "./styles.scss";
import confirmHomeCancellation from "../../../../../../../../api/v1/itinerary/confirmHomeCancellation";
import { RouteComponentProps } from "react-router";
import { CancelHomeModalContentConnectorProps } from "./container";
import dayjs from "dayjs";

export interface ICancelHomeModalContentProps
  extends CancelHomeModalContentConnectorProps,
    RouteComponentProps {
  home: HomesItinerary;
}

export enum CancelStep {
  CancellationInfo,
  ConfirmCancellation,
  LoadingOrProcessing,
}

const CancelHomeModalContent = (props: ICancelHomeModalContentProps) => {
  const { home, setOpenModal } = props;

  const [cancelStep, setCancelStep] = useState<CancelStep>(
    CancelStep.LoadingOrProcessing
  );

  const cancellationInfoRef = useRef<HomeCancelInfoResponse>();

  const prepareCancelReq = useCallback(() => {
    const { id } = home.reservation;

    getCancelInfo(id.value);
  }, [home.reservation]);

  const getCancelInfo = useCallback(
    (customerReservationId: string) => {
      const req: CancellationInfoRequest = {
        customerReservationId,
      };
      setCancelStep(CancelStep.LoadingOrProcessing);
      getHomeCancellationInfo(req).then(
        (cancellationInfo: HomeCancelInfoResponse) => {
          cancellationInfoRef.current = cancellationInfo;

          setCancelStep(CancelStep.CancellationInfo);
        }
      );
    },
    [home.reservation]
  );

  const displayCancelConfirmation = useCallback(() => {
    const { current: cancellationInfo } = cancellationInfoRef;

    if (
      cancellationInfo &&
      "cancelConfirmationCopy" in cancellationInfo?.cancelScenario
    ) {
      setCancelStep(CancelStep.ConfirmCancellation);
    }
  }, []);

  const confirmCancellation = () => {
    const { current: cancellationInfo } = cancellationInfoRef;

    if (cancellationInfo) {
      const req: CancellationInfoRequest = {
        customerReservationId: home.reservation.id.value,
      };

      setCancelStep(CancelStep.LoadingOrProcessing);

      confirmHomeCancellation(req).then(() => {
        setOpenModal({
          type: null,
          selectedItinerary: null,
        });
      });
      // [TO-DO]: Handle failure when we get design
      // .catch((err: string[]) => {
      //   titleRef.current = cancelTitles.CANCEL_FAILED;
      //   subtitlesRef.current = err;

      //   setCancelStep(CancelStep.CancellationError);
      // });
    }
  };

  const renderLoadingOrProcessing = useCallback(
    () => <B2BSpinnerWithText subtitle={constants.LOADING_POLICY} />,
    []
  );
  const PropertyInfo = () => {
    return (
      <Box className="property-info-container">
        <Typography variant="body1" className="property-name">
          {home.reservation.listing.name}
        </Typography>
        <Typography variant="body2" className="property-address">
          {home.reservation.listing.areaName}
          {home.reservation.listing.state
            ? `, ${home.reservation.listing.state}`
            : ""}
        </Typography>
        <Box className="booking-dates">
          <Box className="check-in">
            <Icon name={IconName.Calendar} />
            <Box>
              <Box className="booking-dates-header">Check-in</Box>
              <Box className="booking-dates-date">
                {dayjs(home.reservation.stayDates.from).format("ddd, MMM D")}
              </Box>
            </Box>
          </Box>
          <Box className="check-out">
            <Icon name={IconName.Calendar} />
            <Box>
              <Box className="booking-dates-header">Check-out</Box>
              <Box className="booking-dates-date">
                {dayjs(home.reservation.stayDates.until).format("ddd, MMM D")}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };
  const renderCancellationInfo = () => {
    const { current: cancellationInfo } = cancellationInfoRef;
    return (
      <Box className="cancellation-info-container">
        <Typography variant="h2" className="modal-title">
          {cancellationInfo?.cancelScenario.cancelCopy.title}
        </Typography>
        {cancellationInfo?.cancelScenario.cancelCopy.body.map((cancelInfo) => (
          <Typography variant="body2">{cancelInfo}</Typography>
        ))}
        <Divider className="modal-divider" />
        <PropertyInfo />
        {cancellationInfo?.cancelScenario.cancelCopy.importantInfo.length ? (
          <Box className="info-items-container">
            <Divider className="modal-divider" />
            <ImportantInfoList
              infoItems={
                cancellationInfo?.cancelScenario.cancelCopy.importantInfo
              }
              title={constants.CANCEL_INFO_TITLE}
            />
          </Box>
        ) : null}
        <Divider className="modal-divider" />
        <ActionButton
          className="cancel-button"
          defaultStyle="h4r-primary"
          message={
            cancellationInfo?.cancelScenario.cancelCopy.callToAction ??
            "Cancel vacation rental"
          }
          onClick={displayCancelConfirmation}
        />
      </Box>
    );
  };
  const renderConfirmCancellation = () => {
    const { current: cancellationInfo } = cancellationInfoRef;
    if (
      cancellationInfo?.cancelScenario &&
      "cancelConfirmationCopy" in cancellationInfo?.cancelScenario
    ) {
      return (
        <Box className="confirm-cancellation-container">
          <Typography className="modal-title">
            {cancellationInfo?.cancelScenario.cancelConfirmationCopy!.title}
          </Typography>
          {cancellationInfo?.cancelScenario.cancelConfirmationCopy!.body.map(
            (cancelInfo) => (
              <Typography variant="body2">{cancelInfo}</Typography>
            )
          )}

          <Divider className="modal-divider" />
          <PropertyInfo />
          <Divider className="modal-divider" />
          <ActionButton
            className="confirm-cancel-button"
            defaultStyle="h4r-primary"
            message={
              cancellationInfo?.cancelScenario.cancelConfirmationCopy!
                .callToAction ?? "Cancel vacation rental"
            }
            onClick={confirmCancellation}
          />
        </Box>
      );
    } else {
      return;
    }
  };

  const ModalContent = useMemo(() => {
    switch (cancelStep) {
      // case CancelStep.CancellationError:
      //   return renderCancellationError();
      case CancelStep.CancellationInfo:
        return renderCancellationInfo();
      case CancelStep.ConfirmCancellation:
        return renderConfirmCancellation();
      case CancelStep.LoadingOrProcessing:
        return renderLoadingOrProcessing();
      default:
        return null;
    }
  }, [cancelStep]);

  useEffect(() => {
    prepareCancelReq();
  }, [prepareCancelReq]);

  return <Box className="cancel-home-modal-content">{ModalContent}</Box>;
};

export default CancelHomeModalContent;
