import React from "react";
import { RouteComponentProps } from "react-router";
import {
  MyTripsFilter,
  FlightItineraryState,
  HotelItineraryState,
  CarReservationState,
} from "redmond";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import {
  ActionButton,
  CloseButtonIcon,
  DesktopPopupModal,
  Icon,
  IconName,
  MobilePopoverCard,
} from "halifax";

import { IMissingItinerariesBannerConnectorProps } from "./container";
import {
  CONTACT_SUPPORT_HEADER,
  CONTACT_SUPPORT_NUMBER,
  CONTACT_SUPPORT_TEXT,
  getMissingTripsBannerText,
} from "./constants";
import "./styles.scss";

interface IMissingItinerariesBannerProps
  extends IMissingItinerariesBannerConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
}

const defaultProps: Partial<IMissingItinerariesBannerProps> = {
  isMobile: false,
};

const MissingItinerariesBanner = (props: IMissingItinerariesBannerProps) => {
  const {
    history,
    isMobile,
    tripsFilter,
    pastFlightsFailed,
    upcomingFlightsFailed,
    pastHotelsFailed,
    upcomingHotelsFailed,
    pastCarsFailed,
    upcomingCarsFailed,
    fetchHotels,
    fetchFlights,
    fetchCars,
  } = props;

  const [openContactModal, setOpenContactModal] =
    React.useState<boolean>(false);

  const handleOnClose = () => {
    setOpenContactModal(false);
  };

  const reFetchFailedCalls = () => {
    switch (tripsFilter) {
      case MyTripsFilter.PAST_TRIPS:
        if (pastFlightsFailed) {
          fetchFlights(
            {
              states: [
                FlightItineraryState.Past,
                FlightItineraryState.Canceled,
              ],
              referenceDateTime: dayjs().format(),
            },
            history
          );
        }
        if (pastHotelsFailed) {
          fetchHotels(
            {
              states: [HotelItineraryState.Past, HotelItineraryState.Canceled],
              referenceDateTime: dayjs().format(),
            },
            history,
            /*
              CancellationFailure hotels come through HotelItineraryState.Future, which should be shown in past trips;
              see https://hopchat.slack.com/archives/C02LKB2MVFY/p1679514821365839?thread_ts=1679512218.419669&cid=C02LKB2MVFY
            */
            [HotelItineraryState.Canceled]
          );
        }
        if (pastCarsFailed) {
          fetchCars(
            {
              states: [CarReservationState.Canceled, CarReservationState.Past],
              asOf: new Date().toISOString(),
            },
            history
          );
        }
        break;
      case MyTripsFilter.UPCOMING_TRIPS:
        if (upcomingFlightsFailed) {
          fetchFlights(
            {
              states: [
                FlightItineraryState.Present,
                FlightItineraryState.Future,
              ],
              referenceDateTime: dayjs().format(),
            },
            history
          );
        }
        if (upcomingHotelsFailed) {
          fetchHotels(
            {
              states: [HotelItineraryState.Present, HotelItineraryState.Future],
              referenceDateTime: dayjs().format(),
            },
            history
          );
        }
        if (upcomingCarsFailed) {
          fetchCars(
            {
              states: [CarReservationState.Present, CarReservationState.Future],
              asOf: new Date().toISOString(),
            },
            history
          );
        }
        break;
      case MyTripsFilter.TRAVEL_CREDITS:
        if (pastFlightsFailed) {
          fetchFlights(
            {
              states: [
                FlightItineraryState.Past,
                FlightItineraryState.Canceled,
              ],
              referenceDateTime: dayjs().format(),
            },
            history
          );
        }
        break;
      default:
        break;
    }
  };

  const TripsHeaderContent = (
    <>
      <Typography variant="h2" className="content-header">
        {CONTACT_SUPPORT_HEADER}
      </Typography>
      <Typography variant="h4" className="content-text">
        {CONTACT_SUPPORT_TEXT}
      </Typography>
      <Typography variant="h2" className="content-phone">
        {CONTACT_SUPPORT_NUMBER}
      </Typography>
    </>
  );
  return (
    <Box className={clsx("missing-trips-banner", { mobile: isMobile })}>
      <Box className="missing-trips-text">
        {tripsFilter === MyTripsFilter.PAST_TRIPS ? (
          <Icon name={IconName.PastTripsClockIcon} />
        ) : (
          <Icon name={IconName.EarlyCheckInCalendarIcon} />
        )}
        <Typography
          className="subtitle"
          component="span"
          dangerouslySetInnerHTML={{
            __html: getMissingTripsBannerText(tripsFilter) || "",
          }}
        />
      </Box>
      <Box className="missing-trips-buttons">
        <ActionButton
          className="book-a-trip"
          message="Try Again"
          defaultStyle="h4r-primary"
          onClick={() => {
            reFetchFailedCalls();
          }}
        />
        <ActionButton
          className="book-a-trip"
          message="Contact Support"
          defaultStyle="h4r-secondary"
          onClick={() => {
            setOpenContactModal(true);
          }}
        />
        {isMobile ? (
          <MobilePopoverCard
            className="mobile-contact-support-popup"
            open={openContactModal}
            onClose={handleOnClose}
            centered
            topRightButton={<CloseButtonIcon onClick={handleOnClose} />}
          >
            <Box className="mobile-contact-support-popup-content">
              {TripsHeaderContent}
            </Box>
          </MobilePopoverCard>
        ) : (
          <DesktopPopupModal
            open={openContactModal}
            className="desktop-contact-support-popup"
            onClose={handleOnClose}
            invisibleBackdrop={false}
          >
            <Box className="desktop-contact-support-popup-content">
              {TripsHeaderContent}
            </Box>
          </DesktopPopupModal>
        )}
      </Box>
    </Box>
  );
};

MissingItinerariesBanner.defaultProps = defaultProps;

export default MissingItinerariesBanner;
