export interface Experiment {
  id: string;
  /**
   * @remarks Many experiments use the standard control-available variant system,
   * but some experiments use other custom strings as variant names.
   */
  variant: ExperimentVariant | string;
  /**
   * @deprecated use variant
   */
  group?: string;
}

export interface ExperimentState {
  experiments: Array<Experiment>;
  trackingProperties?: Map<string, string>;
}

export interface ActiveExperimentsResponse {
  experiments: Array<Experiment>;
  trackingProperties: Map<string, string>;
}

export enum ExperimentVariant {
  /**
   * @description The "on" state
   */
  AVAILABLE = "available",
  /**
   * @description The "off" state
   */
  CONTROL = "control",
}
