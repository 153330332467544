import {
  getDurationFromSegments,
  Airport,
  FlightItinerarySlice,
} from "redmond";
import dayjs from "dayjs";

export const removeTimezone = (time: string) =>
  time.replace(/[zZ]|[-+][0-9:]+$/, "");

export const DEPARTURE = "Departure";
export const RETURN = "Return";
export const OUTBOUND = "Outbound";
export const DATE_FORMAT = "ddd MMM, DD";

export const getItinerarySummaryHeaderFromSlice = (
  slice: FlightItinerarySlice,
  airportMap: { [key: string]: Airport }
) => {
  const locationCode =
    slice!.segments[slice!.segments.length - 1].destination.locationCode;
  const cityName = !!airportMap[locationCode]
    ? airportMap[locationCode]!.cityName
    : locationCode;
  return ` to ${cityName}`;
};

export const getMultiCityItinerarySummaryHeader = (
  slice: FlightItinerarySlice,
  airportMap: { [key: string]: Airport }
) => {
  const departingLocationCode = slice!.segments[0].origin.locationCode;
  const departingCityName = !!airportMap[departingLocationCode]
    ? airportMap[departingLocationCode]!.cityName
    : departingLocationCode;
  const arrivingLocationCode =
    slice!.segments[slice!.segments.length - 1].destination.locationCode;
  const arrivingCityName = !!airportMap[arrivingLocationCode]
    ? airportMap[arrivingLocationCode]!.cityName
    : arrivingLocationCode;
  return `${departingCityName} to ${arrivingCityName}`;
};

export const getFormattedDateFromSlice = (
  slice: FlightItinerarySlice,
  isOutgoing: boolean
) => {
  const time = isOutgoing
    ? dayjs(slice?.segments[0].scheduledDeparture)
    : dayjs(slice?.segments[0].scheduledArrival);
  return time.format(DATE_FORMAT);
};

export const getTimeFromSlice = (slice: FlightItinerarySlice) => {
  const dep =
    slice!.segments[0].zonedUpdatedDeparture ||
    slice!.segments[0].updatedDeparture ||
    slice!.segments[0].zonedScheduledDeparture ||
    slice!.segments[0].scheduledDeparture;
  const ret =
    slice!.segments[slice!.segments.length - 1].zonedUpdatedArrival ||
    slice!.segments[slice!.segments.length - 1].updatedArrival ||
    slice!.segments[slice!.segments.length - 1].zonedScheduledArrival ||
    slice!.segments[slice!.segments.length - 1].scheduledArrival;
  const departure = dayjs(removeTimezone(dep));
  const returnTime = dayjs(removeTimezone(ret));
  return `${departure.format("h:mm A")} - ${returnTime.format("h:mm A")}`;
};

export const getDurationFromSlice = (slice: FlightItinerarySlice) => {
  const duration = getDurationFromSegments(
    slice!.segments,
    (departure, arrival) => dayjs(arrival).diff(dayjs(departure), "m")
  );
  const hours = Math.floor(duration / 60);
  const mins = duration - hours * 60;
  return `${hours}h ${mins}m`;
};
