import dayjs from "dayjs";
import { last } from "lodash";
import {
  Airline,
  Airport,
  FlightItinerarySegment,
  FlightItinerarySegmentStatusEnum,
  FlightItinerarySlice,
  TimeGroupingEnum,
  ITimeGrouping,
  ScheduleChange,
  TagColors,
  TagInfo,
  getPlusDays,
  getPlusDaysFromItinerarySegments,
  getTripSegmentsFromItinerarySegments,
  ScheduleChangeChoice,
} from "redmond";

import { TWENTY_FOUR_HOURS } from "../../../FlightCard/constants";
import { SOMETHING_WENT_WRONG } from "../../constants";

export const REVIEW_NEW_ITINERARY = "Review your new itinerary:";
export const VIEW_PREVIOUS_ITINERARY = "View previous itinerary";
export const VIEW_CANCELATION_ELIGIBILITY = "View cancellation eligibility";
export const OUTBOUND = "Outbound";
export const MODIFIED_FLIGHT = "Modified Flight";
export const RECMD_OPTION = "Recommended Option";
export const RETAINED_FLIGHT = "Retained Flight";
export const RETURN = "Return";
export const PASSIVE_COPY = {
  ACCEPTED_FAILURE_TEXT:
    "There was an issue processing your authorization, please try again in a few minutes.",
  ACCEPTED_SUCCESS: "We’ve received your authorization",
  ACCEPTED_SUCCESS_TEXT:
    "If we’re able to secure this flight, a confirmation email will be sent and My Trips will be updated shortly after (please allow up to 72 hours). If no longer available, a customer service agent will contact you as soon as possible to discuss alternate options.",
};
export const CONF_COPY = {
  ACCEPTED_FAILURE_TEXT:
    "There was an issue processing your acceptance, please try again in a few minutes.",
  ACCEPTED_SUCCESS: "This change has been accepted",
  ACCEPTED_SUCCESS_TEXT:
    "A confirmation email will be sent and My Trips will be updated shortly, generally within a few minutes but please allow up to 72 hours.<br/><br/>You can also confirm your revised flight details on the airline’s website using the confirmation code we originally provided to you.",
  DENY_SUCCESS: "We’ve received your new flight time request.",
  DENY_SUCCESS_TEXT:
    "A customer service agent will contact you within 24 hours to help find a comparable flight.",
  DENY_FAILURE_TEXT: "We were unable to process your schedule update.",
};

export const SCHEDULE_CHANGE_FAILURE_TITLE = SOMETHING_WENT_WRONG;
export const DONE = "Done";
export const RETRY = "Retry";
export const CONTACT_AIRLINE_SUBTITLE =
  "An airline representative will be able to discuss your available flight options.";
export const CONTACT_AIRLINE_TITLE = "Please contact the airline";
export const CONTACT_CUSTOMER_SUPPORT = "Contact customer support";
export const REFER_AIRLINE_HOURS_LIMIT = 75;
export const AFTER_MINS = 0;
export const BEFORE_MINS = 59;
export const TIME_CHOICE_FMT = "YYYY-MM-DDTHH:mm";

export const SCHEDULE_CHANGE_FORM_TITLES = {
  acceptButtonTitle: (isPassive = false) =>
    isPassive ? "Authorize attempt to book" : "Accept this change",
  acceptDetails: (isPassive = false) =>
    isPassive
      ? "We'll confirm availability and send a confirmation email if successful. A customer service agent will contact you if no longer available."
      : "This change will be finalized and a confirmation email will be sent shortly after. No further action is necessary",
  denyButtonTitle: (isPassive = false) =>
    `Decline this ${isPassive ? "option" : "change"}`,
  denyDetails: (isPassive = false, flightImminent = false) => {
    if (isPassive)
      return "We'll collect your flight preferences and a customer service agent will attempt to find a suitable alternative (additional charges may apply).";
    if (flightImminent)
      return "As your flight’s departure is imminent, you’ll need to contact the airline to discuss available options.";

    return "Provide your flight preferences and an agent will search for suitable alternatives (additional charges may apply)";
  },
  description: (airline: string, isPassive = false) =>
    isPassive
      ? `While ${airline} canceled your original flight without providing an alternative, our recommended flight is most suitable and available at no additional cost.`
      : "Where no response is received from you and automatic acceptance has occurred, please note that any further change or cancellation request will be subject to the airline’s fare rules and charges if applicable.",
  headingCopy: (airline: string, isPassive = false) =>
    isPassive
      ? "We've found a suitable replacement for your canceled flight"
      : `Accept or Decline the change made by ${airline}`,
  headingTwoCopy: (airline: string, isPassive = false) =>
    isPassive
      ? "<b>Authorize</b> or <b>Decline</b> our recommended option:"
      : `<b>Accept</b> or <b>Decline</b> the change made by ${airline}`,
  passiveSegmentSubtitle: (airline: string) =>
    `While ${airline} canceled your original flight without providing an alternative, our recommended flight is most suitable and available at no added cost.`,
  subheadingTwo: (isPassive = false, time = TWENTY_FOUR_HOURS) =>
    isPassive
      ? "Note that this flight is not reserved and is subject to availability once we receive your authorization"
      : `This change will be automatically accepted within ${time} unless declined`,
  timePreferenceCopy: (isOutbound: boolean) =>
    `Select a departure time preference for your new <b>${
      isOutbound ? "Outbound" : "Return"
    }</b> flight (required)`,
};

export const TIMES: ITimeGrouping[] = [
  {
    grouping: TimeGroupingEnum.EARLY_MORNING,
    label: "Early Morning",
    sublabel: "12:00am - 4:59am",
  },
  {
    grouping: TimeGroupingEnum.AFTERNOON,
    label: "Afternoon",
    sublabel: "12:00pm - 5:59pm",
  },
  {
    grouping: TimeGroupingEnum.MORNING,
    label: "Morning",
    sublabel: "5:00am - 11:59am",
  },
  {
    grouping: TimeGroupingEnum.EVENING,
    label: "Evening",
    sublabel: "6:00pm - 11:59pm",
  },
];

export const PREF_RANGES = {
  [TimeGroupingEnum.EARLY_MORNING]: {
    afterHours: 0,
    beforeHours: 4,
  },
  [TimeGroupingEnum.MORNING]: {
    afterHours: 5,
    beforeHours: 11,
  },
  [TimeGroupingEnum.AFTERNOON]: {
    afterHours: 12,
    beforeHours: 17,
  },
  [TimeGroupingEnum.EVENING]: {
    afterHours: 18,
    beforeHours: 23,
  },
  anytime: {
    afterHours: 0,
    beforeHours: 23,
  },
};

export const statusIsYK = (seg: FlightItinerarySegment) =>
  seg.status === FlightItinerarySegmentStatusEnum.UnMapped;

export const getFlightDetailsProps = (
  segments: FlightItinerarySegment[],
  airportMap: { [key: string]: Airport },
  airlineMap: { [key: string]: Airline }
) => ({
  segments: getTripSegmentsFromItinerarySegments({
    segments,
    airportMap,
    airlineMap,
  }),
  departureTime: segments[0].scheduledDeparture || "",
  // TODO find out where to get planeInfo and fareClass info
  planeInfo: "",
  fareClass: "",
  plusDays: getPlusDaysFromItinerarySegments(segments),
});

export const OG_getFlightDetailsProps = (
  scheduleChange: ScheduleChange,
  isOutgoing: boolean,
  airportMap: { [key: string]: Airport },
  airlineMap: { [key: string]: Airline }
) => {
  const slice =
    !isOutgoing && scheduleChange.next.length > 1
      ? scheduleChange.next[1]
      : scheduleChange.next[0];
  const YKSegments = slice?.segments.filter(statusIsYK);
  const segments = YKSegments?.length ? YKSegments : slice?.segments;

  return {
    departureTime: segments[0].scheduledDeparture || "",
    // TODO find out where to get planeInfo and fareClass info
    fareClass: "",
    planeInfo: "",
    plusDays: getPlusDays(slice),
    segments: getTripSegmentsFromItinerarySegments({
      segments,
      airportMap,
      airlineMap,
    }),
    tripSlice: slice,
  };
};

export const getItineraryDetailsHeader = (
  scheduleChange: ScheduleChange,
  isOutgoing: boolean,
  airportMap: { [key: string]: Airport },
) => {
  const [outboundSlice, returnSlice] = scheduleChange.next;
  let destSegment;

  if (isOutgoing) {
    destSegment = last(outboundSlice.segments);
  } else if (returnSlice) {
    destSegment = last(returnSlice.segments);
  }

  if (destSegment) {
    const airportCode = destSegment.destination.locationCode;

    return ` to ${airportMap[airportCode!]?.cityName}`;
  }

  return "";
};

export const getTagInfo = (
  segments: FlightItinerarySegment[],
  hasChanged = false
): TagInfo => {
  const hasYK = segments.some(statusIsYK);
  const tagInfo: TagInfo = {
    label: hasChanged ? MODIFIED_FLIGHT : RETAINED_FLIGHT,
    type: hasChanged || hasYK ? TagColors.YELLOW : TagColors.GREEN,
  };

  if (hasYK) tagInfo.label = RECMD_OPTION;

  return tagInfo;
};

/**
 * @param val The value of a selected checkbox
 * @param slice The outbound or return slice
 * @returns
 */
export const getTimeChoice = (
  val: TimeGroupingEnum,
  slice: FlightItinerarySlice
): ScheduleChangeChoice => {
  const pref = PREF_RANGES[val] || PREF_RANGES.anytime;
  const segment = slice.segments[0];
  const { scheduledDeparture, zonedScheduledDeparture } = segment!;
  const depDateTime = zonedScheduledDeparture || scheduledDeparture;

  const departAfter = dayjs(depDateTime)
    .set("hour", pref.afterHours)
    .set("minute", AFTER_MINS) // 0
    .format(TIME_CHOICE_FMT);
  const departBefore = dayjs(depDateTime)
    .set("hour", pref.beforeHours)
    .set("minute", BEFORE_MINS) // 59
    .format(TIME_CHOICE_FMT);

  return {
    departAfter,
    departBefore,
  };
};

export const isUpdated = (slice: FlightItinerarySlice) =>
  !!slice &&
  slice.segments.some(
    (s) =>
      s.status === FlightItinerarySegmentStatusEnum.ConfirmedPendingNewChange ||
      s.status === FlightItinerarySegmentStatusEnum.UnMapped ||
      s.status === FlightItinerarySegmentStatusEnum.UnMappedPersisted
  );

/**
 * @description The slices in a SKCH can have multiple recommendations (origin -> dest segments).
 * This grabs the latest subset that matches that criteria.
 * If there are YK segments (agent recommendations), it'll prioritize those.
 */
export const getLatestRecommendedSegments = (
  segments: FlightItinerarySegment[] = [],
  origin: string,
  destination: string
): FlightItinerarySegment[] => {
  // if atleast 1 YK segment, only use YK segments
  const YKSegments = segments.filter(statusIsYK);
  const options = YKSegments.length ? YKSegments : segments;
  let originIdx = 0;
  let destIdx = segments.length;

  for (let i = 0; i < options.length; i += 1) {
    const {
      origin: { locationCode: originCode },
      destination: { locationCode: destCode },
    } = options[i];

    if (originCode === origin) originIdx = i;
    if (destCode === destination) destIdx = i;
  }

  if (destIdx >= originIdx) return options.slice(originIdx, destIdx + 1);

  return [];
};
