import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../../../reducers/types";
import {
  fetchCars,
  fetchFlights,
  fetchHotels,
} from "../../../../actions/actions";
import MissingItinerariesBanner from "./component";
import {
  getTripsFilter,
  getFetchUpcomingHotelsFailed,
  getFetchPastFlightsFailed,
  getFetchPastHotelsFailed,
  getFetchUpcomingFlightsFailed,
  getFetchUpcomingCarsFailed,
  getFetchPastCarsFailed,
} from "../../../../reducer";

const mapStateToProps = (state: IStoreState) => ({
  tripsFilter: getTripsFilter(state),
  pastFlightsFailed: getFetchPastFlightsFailed(state),
  upcomingFlightsFailed: getFetchUpcomingFlightsFailed(state),
  pastHotelsFailed: getFetchPastHotelsFailed(state),
  upcomingHotelsFailed: getFetchUpcomingHotelsFailed(state),
  pastCarsFailed: getFetchPastCarsFailed(state),
  upcomingCarsFailed: getFetchUpcomingCarsFailed(state),
});

const mapDispatchToProps = {
  fetchHotels,
  fetchFlights,
  fetchCars,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IMissingItinerariesBannerConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMissingItinerariesBanner = connector(
  withRouter(MissingItinerariesBanner)
);
