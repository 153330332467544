import React, { useContext, useEffect, useMemo, useState } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import {
  BookedFlightItineraryWithDepartureTime,
  HotelItinerary,
  ItineraryWithType,
  ItineraryEnum,
  CarReservation,
  MyTripsFilter,
  PRODUCT_FEED_LOADING,
  ProductFeedLoadingProperties,
  HomesItinerary,
  CallState,
} from "redmond";
import { Box, Divider } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import clsx from "clsx";

import { ItineraryListConnectorProps } from "./container";
import { FlightCard } from "./components/FlightCard";
import { HotelCard } from "./components/HotelCard";
import { CarCard } from "./components/CarCard";
import { ItinerariesModal } from "./components/ItinerariesModal";
import { PAGE_SIZE } from "./constants";
import { MissingItinerariesBanner } from "./components/MissingItinerariesBanner";

import "./styles.scss";
import { FlightToHotelCrossSell } from "./components/FlightToHotelCrossSell";
import {
  useExperiments,
  getExperimentVariantCustomVariants,
  ActiveExperiments,
  HOTEL_CROSS_SELL_V3_VARIANTS,
  CONTROL,
} from "../../../../context/experiments";
import { ClientContext } from "../../../../App";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import { HomeCard } from "./components/HomeCard";
import {
  getIsCorporateForCar,
  getIsCorporateForFlight,
  getIsCorporateForHotel,
} from "../../utils";

export interface IItineraryListProps
  extends ItineraryListConnectorProps,
    RouteComponentProps<{}, StaticContext, { prevPath?: string }> {
  isMobile?: boolean;
  showDisruptionProtectionElements?: boolean;
}

const defaultProps: Partial<IItineraryListProps> = {
  showDisruptionProtectionElements: false,
  isMobile: false,
};

export const scrollTopWithOffset = (element: HTMLDivElement) => {
  const offset = 221; // header (211px) + padding below header (10px)
  const bodyRect = document.body.getBoundingClientRect().top;
  const elementRect = element.getBoundingClientRect().top;
  const elementPosition = elementRect - bodyRect;
  const offsetPosition = elementPosition - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
};

const getItineraryId = (itinerary: ItineraryWithType) => {
  if (itinerary.type === ItineraryEnum.Flight) {
    const flight = itinerary as BookedFlightItineraryWithDepartureTime;
    return flight.bookedItinerary.id;
  }
  if (itinerary.type === ItineraryEnum.Hotel) {
    const hotel = itinerary as HotelItinerary;
    return hotel.reservation.reservationId;
  }
  if (itinerary.type === ItineraryEnum.Car) {
    const car = itinerary as CarReservation;
    return car.bookResult.groundBookingId;
  }
  if (itinerary.type === ItineraryEnum.Home) {
    const home = itinerary as HomesItinerary;
    return home.reservation.id.value;
  }
  return "";
};

const ItineraryList = (props: IItineraryListProps) => {
  const {
    history,
    itinerariesToDisplay,
    isMobile,
    showDisruptionProtectionElements,
    populateTripQueryParams,
    selectedFlight,
    selectedHotel,
    selectedCar,
    selectedHome,
    setSelectedHome,
    setSelectedFlight,
    setSelectedHotel,
    tripsFilter,
    setSelectedCar,
    selectedTripId,
    hasError,
    flightFutureCallState,
    hotelFutureCallState,
    fetchInitialUpcomingFlightCrossSell,
    lodgings,
    tripSearchQuery,
    fetchActiveHotelCrossSell,
    firstCrossSellOffer,
    crossSellOfferCallState,
    nextPageToken,
    fetchMoreUpcomingFlightCrossSell,
  } = props;

  const { isAgentPortal } = useContext(ClientContext);

  const [expandedCard, setExpandedCard] = useState(selectedTripId);

  const expState = useExperiments();

  const hotelCrossSellV3Experiment = getExperimentVariantCustomVariants(
    expState.experiments,
    ActiveExperiments.HOTEL_CROSS_SELL_V3_EXPERIMENT,
    HOTEL_CROSS_SELL_V3_VARIANTS
  );
  const isHotelCrossSellV3Experiment = useMemo(
    () => hotelCrossSellV3Experiment !== CONTROL,
    [hotelCrossSellV3Experiment]
  );

  useEffect(() => {
    const newExpandedCard = selectedTripId;
    if (newExpandedCard !== expandedCard) {
      setExpandedCard(newExpandedCard);
    }
  }, [selectedTripId]);

  useEffect(() => {
    if (isHotelCrossSellV3Experiment) {
      fetchActiveHotelCrossSell();
    } else if (flightFutureCallState && hotelFutureCallState) {
      fetchInitialUpcomingFlightCrossSell();
    }

    const properties: ProductFeedLoadingProperties = {
      feed_type: "cross_sell_hotels",
      feed_placement: "mytrips",
    };
    trackEvent({ eventName: PRODUCT_FEED_LOADING, properties });
  }, [flightFutureCallState, hotelFutureCallState, expState]);

  useEffect(() => {
    // If no active cross sell offer after fetching (checking callstate), see if v1 xsell is applicable (xsell UI for most upcoming flight with no offer)
    if (
      (!firstCrossSellOffer ||
        !firstCrossSellOffer.destinationDetail.lodgingSelection) &&
      crossSellOfferCallState !== CallState.NotCalled &&
      flightFutureCallState &&
      hotelFutureCallState
    ) {
      fetchInitialUpcomingFlightCrossSell();
    }
  }, [
    firstCrossSellOffer,
    crossSellOfferCallState,
    flightFutureCallState,
    hotelFutureCallState,
  ]);

  useEffect(() => {
    if (lodgings?.length && lodgings?.length < 8 && nextPageToken) {
      fetchMoreUpcomingFlightCrossSell();
    }
  }, [lodgings, nextPageToken]);

  const onCloseCard = (itinerary: ItineraryWithType) => {
    setExpandedCard("");
    if (itinerary.type === ItineraryEnum.Flight) {
      setSelectedFlight(null);
    }
    if (itinerary.type === ItineraryEnum.Hotel) {
      setSelectedHotel(null);
    }
    if (itinerary.type === ItineraryEnum.Car) {
      setSelectedCar(null);
    }
    if (itinerary.type === ItineraryEnum.Home) {
      setSelectedHome(null);
    }
    populateTripQueryParams(history);
  };

  // clear open card on search, prevents page scrolling to open card
  useEffect(() => {
    if (tripSearchQuery) {
      setExpandedCard("");
      setSelectedFlight(null);
      setSelectedHotel(null);
      setSelectedCar(null);
      populateTripQueryParams(history);
    }
  }, [tripSearchQuery]);

  const onExpandCard = (itinerary: ItineraryWithType) => {
    setExpandedCard(getItineraryId(itinerary));

    if (itinerary.type === ItineraryEnum.Flight) {
      if (selectedHotel) {
        setSelectedHotel(null);
      }
      if (selectedCar) {
        setSelectedCar(null);
      }
      if (selectedHome) {
        setSelectedHome(null);
      }
      setSelectedFlight(itinerary as BookedFlightItineraryWithDepartureTime);
    }
    if (itinerary.type === ItineraryEnum.Hotel) {
      if (selectedFlight) {
        setSelectedFlight(null);
      }
      if (selectedCar) {
        setSelectedCar(null);
      }
      if (selectedHome) {
        setSelectedHome(null);
      }
      setSelectedHotel(itinerary as HotelItinerary);
    }
    if (itinerary.type === ItineraryEnum.Car) {
      if (selectedFlight) {
        setSelectedFlight(null);
      }
      if (selectedHotel) {
        setSelectedHotel(null);
      }
      if (selectedHome) {
        setSelectedHome(null);
      }
      setSelectedCar(itinerary as CarReservation);
    }
    if (itinerary.type === ItineraryEnum.Home) {
      if (selectedFlight) {
        setSelectedFlight(null);
      }
      if (selectedHotel) {
        setSelectedHotel(null);
      }
      if (selectedCar) {
        setSelectedCar(null);
      }
      setSelectedHome(itinerary as HomesItinerary);
    }
    populateTripQueryParams(history);
  };

  const onExpandToggleClick =
    (itinerary: ItineraryWithType) => (cardId: string) =>
      cardId === expandedCard
        ? onCloseCard(itinerary)
        : onExpandCard(itinerary);

  const renderFlightCard = (itinerary: ItineraryWithType) => (
    <FlightCard
      isMobile={isMobile}
      onExpandCard={onExpandToggleClick(itinerary)}
      expandedCard={expandedCard}
      flight={itinerary as BookedFlightItineraryWithDepartureTime}
      showDisruptionProtectionElements={showDisruptionProtectionElements}
      isCorporate={getIsCorporateForFlight(
        itinerary as BookedFlightItineraryWithDepartureTime
      )}
    />
  );

  const renderHotelCard = (itinerary: ItineraryWithType) => (
    <HotelCard
      key={(itinerary as HotelItinerary).reservation.reservationId}
      isMobile={isMobile}
      hotel={itinerary as HotelItinerary}
      onExpandCard={onExpandToggleClick(itinerary)}
      expandedCard={expandedCard}
      isCorporate={getIsCorporateForHotel(itinerary as HotelItinerary)}
    />
  );

  const renderHomeCard = (itinerary: ItineraryWithType) => (
    <HomeCard
      key={(itinerary as HomesItinerary).reservation.id.value}
      isMobile={isMobile}
      home={itinerary as HomesItinerary}
      onExpandCard={onExpandToggleClick(itinerary)}
      expandedCard={expandedCard}
    />
  );

  const renderCarCard = (itinerary: ItineraryWithType) => (
    <CarCard
      isMobile={isMobile}
      onExpandCard={onExpandToggleClick(itinerary)}
      expandedCard={expandedCard}
      car={itinerary as CarReservation}
      isCorporate={getIsCorporateForCar(itinerary as CarReservation)}
    />
  );

  const renderTripCard = (itinerary: ItineraryWithType) => {
    if (itinerary.type === ItineraryEnum.Flight) {
      return renderFlightCard(itinerary);
    }
    if (itinerary.type === ItineraryEnum.Hotel) {
      return renderHotelCard(itinerary);
    }
    if (itinerary.type === ItineraryEnum.Home) {
      return renderHomeCard(itinerary);
    }
    if (itinerary.type === ItineraryEnum.Car) {
      return renderCarCard(itinerary);
    }
    return null;
  };

  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [itineriesToShow, setItinerariesToShow] = useState<ItineraryWithType[]>(
    []
  );
  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    if (selectedCar) setSelectedCar(null);
    if (selectedHotel) setSelectedHotel(null);
    if (selectedFlight) setSelectedFlight(null);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    setPage(1);
  }, [tripsFilter]);

  const setTripsPage = () => {
    let selectedItinerary = null;
    if (selectedCar) {
      selectedItinerary = { ...selectedCar, type: ItineraryEnum.Car };
    }
    if (selectedHotel) {
      selectedItinerary = { ...selectedHotel, type: ItineraryEnum.Hotel };
    }
    if (selectedFlight) {
      selectedItinerary = { ...selectedFlight, type: ItineraryEnum.Flight };
    }
    const selectedItineraryId =
      selectedItinerary && getItineraryId(selectedItinerary);
    const selectedIndex = itinerariesToDisplay.findIndex(
      (itinerary) => getItineraryId(itinerary) === selectedItineraryId
    );
    const currentPage = Math.ceil((selectedIndex + 1) / PAGE_SIZE);
    if (selectedIndex !== -1 && page !== currentPage) {
      setPage(currentPage);
    }
  };

  const getCurrentItems = () => {
    const startIndex = (page - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    return itinerariesToDisplay.slice(startIndex, endIndex);
  };

  const getTotalPages = () =>
    Math.ceil(itinerariesToDisplay.length / PAGE_SIZE);

  useEffect(() => {
    setItinerariesToShow(getCurrentItems());
  }, [page]);

  useEffect(() => {
    if (itinerariesToDisplay.length > 0) {
      const totalPagesToUpdate = getTotalPages();
      if (totalPagesToUpdate !== totalPages) {
        setTotalPages(getTotalPages());
      }
      setTripsPage();
      setItinerariesToShow(getCurrentItems());
    } else {
      setItinerariesToShow([]);
    }
  }, [itinerariesToDisplay]);

  return (
    <Box className={clsx({ mobile: isMobile }, "my-trips-list")}>
      {itineriesToShow.map((itinerary) => (
        <React.Fragment key={getItineraryId(itinerary)}>
          {renderTripCard(itinerary)}
          {!isMobile && <Divider className="itinerary-card-divider" />}
        </React.Fragment>
      ))}
      <ItinerariesModal isMobile={isMobile} />

      {hasError && <MissingItinerariesBanner isMobile={isMobile} />}
      {totalPages > 1 && (
        <Box
          className={clsx("trips-pagination-container", { mobile: isMobile })}
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
          />
        </Box>
      )}
      {lodgings?.length &&
        tripsFilter === MyTripsFilter.UPCOMING_TRIPS &&
        page === 1 &&
        !isAgentPortal && <FlightToHotelCrossSell isMobile={isMobile} />}
    </Box>
  );
};

ItineraryList.defaultProps = defaultProps;

export default ItineraryList;
