import {
  PriceDropProtection,
  PriceDropProtectionEnum,
  IsEligible,
  PRICE_DROP_PROTECTION_MODAL_SUBTITLE,
} from "redmond";

export const PRICE_DROP_LINK_LABEL = "Free Price Drop Protection";
export const PDP_TITLE = "How it works";
export const PDP_CTA_TEXT = "Read terms and conditions";
export const PDP_HEADER =
  "Book now with <span class='font-regular'>free price drop protection</span>";

const getMonitoringDaysCount = (protection: PriceDropProtection) => {
  const days =
    protection.PriceDropProtection === PriceDropProtectionEnum.IsEligible
      ? (protection as IsEligible).monitoringDuration.inSeconds / 86400
      : 0;
  return days;
};

const getMaxRefund = (protection: PriceDropProtection) => {
  const maxRefund =
    protection.PriceDropProtection === PriceDropProtectionEnum.IsEligible &&
    protection.maximumRefund.display;
  return maxRefund;
};

export const getSubtitle = (protection: PriceDropProtection, isPriceDropCreditEnabled: boolean) => {
  const monitoringDays = getMonitoringDaysCount(protection);
  const maxRefund = getMaxRefund(protection);
  return PRICE_DROP_PROTECTION_MODAL_SUBTITLE(monitoringDays, maxRefund, isPriceDropCreditEnabled)
};
